/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  role: null,
  username: null,
  email: null,
  universityName: null,
  diplomas: [],
  currentDiploma: {},
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    SIGN_IN: (state, action) => {
      state.accessToken = action.payload.token;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.universityName = action.payload.universityName;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SIGN_IN } = studentSlice.actions;

export default studentSlice.reducer;
