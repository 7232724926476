/* eslint-disable react/no-unescaped-entities */
import moment from "moment";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ADD_DIPLOMAS_FROM_CSV } from "../../store/features/diplomasSlice/diplomasSlice";
import { useGetDiplomasWithErrorMutation } from "../../store/service/diplomasService";
import { read, utils, set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);
import { convertNumbers2English } from "../../utils/convert";
import FileInput from "../../components/input/FileInput";
import { isSignatureDateBeforeApprovalDate } from "../../utils/dateUtils/checkDate";
// import { excelSerialNumberToDate } from "../../utils/dateUtils/convertDateTimeStambyFormatDate";
import { uploadJSON } from "../../utils/convertArraytoJson";
import { downloadFileDiploma } from "../../utils/downloadFile";
import { toastErrorServer } from "../../components/toast/Toast";

function AddDiplomasUploader({
  setOpen,
  setNonExistingStudents,
  setErrorObject,
  setIsLoadingData,
  setError,
}) {
  const dispatch = useDispatch();
  const [getErroredTable] = useGetDiplomasWithErrorMutation();
  const [isThereInputErrors, setIsThereInputErrors] = useState(false);
  const diplomasFromRedux = useSelector((state) => state.diploma.diplomasToAdd);

  /**
   * @function onClear
   * @description this function to clear the metadata
   *
   * @return
   */
  function onClear() {
    let emptyStudents = [];
    dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAdd: emptyStudents }));
  }

  /**
   * @function isDateNotValid
   * @description this function to valid the field date
   *
   * @param {*} field
   *
   * @return Date
   */
  // function isDateNotValid(field) {
  //   if (typeof field === "string") {
  //     return (
  //       moment(convertNumbers2English(field)).format("DD-MM-YYYY") ===
  //       "Invalid date"
  //     );
  //   } else {
  //     return (
  //       field !== undefined &&
  //       moment(
  //         convertNumbers2English(excelSerialNumberToDate(field).toString()),
  //         "DD-MM-YYYY",
  //         "YYYY-MM-DD",
  //         "MM-DD-YYYY"
  //       ).format("DD-MM-YYYY") === "Invalid date"
  //     );
  //   }
  // }
  function areAllCharactersNumbers(inputString) {
    // Using regex to check if all characters are numbers
    const isNumeric = /^[0-9]+$/.test(inputString);
    return isNumeric;
  }
  function convertSerialNumberToDate(serialNumber) {
    const millisecondsPerDay = 86400000;
    const epoch = new Date("1900-01-01T00:00:00Z");
    const offset = (serialNumber - 1) * millisecondsPerDay - millisecondsPerDay;
    const date = new Date(epoch.getTime() + offset);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    console.log("after ********", { day, month, year });
    return `${day}-${month}-${year}`;
  }
  function convertSerialNumberToDateIfDateIsString(serialNumber) {
    const millisecondsPerDay = 86400000;
    const epoch = new Date("1900-01-01T00:00:00Z");
    const offset = (serialNumber - 1) * millisecondsPerDay;
    const date = new Date(epoch.getTime() + offset);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }
  function convertDateToString(dateString) {
    const date = moment(dateString);
    return date.format("DD-MM-YYYY");
  }
  function writeDate(field) {
    console.log({ field });
    console.log(typeof field);
    if (typeof field === "string") {
      console.log("inside string");
      if (areAllCharactersNumbers(field)) {
        return convertSerialNumberToDateIfDateIsString(
          convertNumbers2English(field?.toString())
        );
      } else {
        return moment(convertNumbers2English(field.toString())).format(
          "DD-MM-YYYY"
        );
      }
    } else if (typeof field === "number") {
      console.log("inside number");
      return convertSerialNumberToDate(
        convertNumbers2English(field?.toString())
      );
    } else {
      console.log("inside date");
      return convertDateToString(field?.toString());
    }
  }
  /**
   * @function writeDate
   * @description this function to valid the field date
   *
   * @param {*} field
   *
   * @return Date
   */
  // function writeDate(field) {
  //   if (typeof field === "string") {
  //     return moment(
  //       convertNumbers2English(field.toString()),
  //       "DD-MM-YYYY",
  //       "YYYY-MM-DD",
  //       "MM-DD-YYYY"
  //     ).format("DD-MM-YYYY");
  //   } else {
  //     return excelSerialNumberToDate(convertNumbers2English(field.toString()));
  //   }
  // }

  /**
   * @function isValidation
   * @description this function to valide the xlsx and return metadata clear
   *
   * @param {*} result
   * @param {*} errorObj
   * @param {*} errorLength
   *
   * @return
   */
  const isValidation = (results, errorObj, errorLength) => {
    let myDiplomas = [];
    for (let index = 0; index < results.length; index++) {
      if (
        results[index]["firstName"] === undefined ||
        results[index]["lastName"] === undefined ||
        results[index]["nin"] === undefined ||
        results[index]["type"] === undefined ||
        results[index]["speciality"] === undefined ||
        results[index]["year"] === undefined ||
        writeDate(results[index]["signatureDate"]) === "Invalid date" ||
        writeDate(results[index]["approvalDate"]) === "Invalid date" ||
        isSignatureDateBeforeApprovalDate(
          writeDate(results[index]["approvalDate"]),
          writeDate(results[index]["signatureDate"])
        )
      ) {
        let colonnes = [];
        if (results[index]["firstName"] === undefined) {
          colonnes.push("الاسم الأول");
          colonnes.push(" , ");
        }
        if (results[index]["lastName"] === undefined) {
          colonnes.push("اسم العائلة ");
          colonnes.push(" , ");
        }
        if (results[index]["nin"] === undefined) {
          colonnes.push(" المعرّف");
          colonnes.push(" , ");
        }
        if (results[index]["type"] === undefined) {
          colonnes.push(" نوع الشهادة");
          colonnes.push(" , ");
        }
        if (results[index]["speciality"] === undefined) {
          colonnes.push(" الاختصاص");
          colonnes.push(" , ");
        }
        if (results[index]["year"] === undefined) {
          colonnes.push(" ألسنة");
          colonnes.push(" , ");
        }
        if (writeDate(results[index]["signatureDate"]) === "Invalid date") {
          colonnes.push(" تاريخ الإمضاء");
          colonnes.push(" , ");
        }
        if (writeDate(results[index]["approvalDate"]) === "Invalid date") {
          colonnes.push(" تاريخ المصادقة");
          colonnes.push(" , ");
        }
        if (
          isSignatureDateBeforeApprovalDate(
            writeDate(results[index]["approvalDate"]),
            writeDate(results[index]["signatureDate"])
          )
        ) {
          colonnes.push(" تاريخ المصادقة يجب أن يكون قبل تاريخ الإمضاء");
          colonnes.push(" , ");
        }

        errorObj.push({
          ligne: index + 1 + errorLength,
          colonnes,
        });
        index++;
      } else {
        const myDiplomaUploaded = {
          firstName: results[index]["firstName"],
          lastName: results[index]["lastName"],
          nin: results[index]["nin"],
          type: results[index]["type"],
          speciality: results[index]["speciality"],
          year: results[index]["year"],
          signatureDate: writeDate(results[index]["signatureDate"]),
          honors: results[index]["honors"],
          approvalDate: writeDate(results[index]["approvalDate"]),
          average: results[index]["average"],
          ligne: index + errorLength,
        };

        myDiplomas.push(myDiplomaUploaded);
      }
    }

    if (errorObj.length > 0) {
      setError(errorObj);
      setIsThereInputErrors(true);
      setOpen(true);
    }

    return myDiplomas;
  };

  /**
   * @function isHeaderValid
   * @description this function to check the header of file xlsx
   *
   * @param {*} header
   *
   * @return
   */
  function isHeaderValid(header) {
    return (
      header.includes("firstName") &&
      header.includes("lastName") &&
      header.includes("nin") &&
      header.includes("type") &&
      header.includes("speciality") &&
      header.includes("year")
    );
  }

  /**
   * @function handleFileChange
   * @description this function to get the metadata in the file and check this metadata
   *               getErroredTable to check diplomas exist or nin student
   *
   * @param {*} file
   *
   * @return
   */
  function handleFileChange(file) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const dataNew = new Uint8Array(event.target.result);
      const workbook = read(dataNew, {
        type: "array",
        cellDates: true,
        cellNF: false,
        cellText: false,
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const results = utils.sheet_to_json(worksheet, { raw: false });
      const header = utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];

      if (isHeaderValid(header)) {
        let errorObj = [];
        let filteredArr = uploadJSON(results).reduce((acc, current) => {
          const x = acc.find((item) => item.nin === current.nin);

          if (!x && current.nin !== undefined) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        let data = { filteredArr };
        console.log({ data });
        getErroredTable({ data }).then((res) => {
          const MetadataHeader = {
            approvalDate: "approvalDate",
            average: "average",
            firstName: "firstName",
            honors: "honors",
            lastName: "lastName",
            ligne: 0,
            nin: "nin",
            signatureDate: "signatureDate",
            speciality: "speciality",
            type: "type",
            year: "year",
          };

          if (
            isThereInputErrors ||
            res.data.data.errToReturn.length !== 0 ||
            res.data.data.existingDiploma.length !== 0
          ) {
            setOpen(true);
            if (res.data.data.errToReturn.length !== 0) {
              setNonExistingStudents(res.data.data.errToReturn);
            }
            if (res.data.data.existingDiploma.length !== 0) {
              setErrorObject(res.data.data.existingDiploma);
            }
          }
          setIsLoadingData(false);

          let myDiplomas = isValidation(
            res.data.data.cleanStudents,
            errorObj,
            res.data.data.existingDiploma.length
          );

          dispatch(
            ADD_DIPLOMAS_FROM_CSV({
              diplomasToAdd: [MetadataHeader, ...myDiplomas],
            })
          );
        });
      } else {
        toastErrorServer("الرجاء التثبت في المعطيات !");
      }
    };

    reader.readAsArrayBuffer(file);
  }
  return (
    <>
      <div className="flex flex-col items-center justify-end w-full p-4 rounded bg-blue-50">
        <p className="w-full text-right">
          قم برفع ملف اكسل مثل هذا الأنموذج"Excel"
          <span
            className="font-bold text-red-700 cursor-pointer"
            onClick={downloadFileDiploma}
          >
            (اضغط هنا)
          </span>
          يتضمن بيانات الشهادات.
        </p>
        <div className="w-full">
          <FileInput
            onChange={handleFileChange}
            OnClear={onClear}
            isUploaded={diplomasFromRedux.length > 0}
          />
        </div>
      </div>
    </>
  );
}
AddDiplomasUploader.propTypes = {
  setOpen: PropTypes.func,
  error: PropTypes.array,
  nonExistingStudents: PropTypes.array,
  setError: PropTypes.func,
  errorObject: PropTypes.array,
  setNonExistingStudents: PropTypes.func,
  setErrorObject: PropTypes.func,
  setIsLoadingData: PropTypes.func,
  errorObj: PropTypes.array,
};
export default AddDiplomasUploader;
