import React from "react";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);
const DoughnutChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      showLabel: true,
      legend: {
        position: "bottom",
        padding: "10",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
      labels: {
        render: "percentage",
        precision: 2,
      },
    },
  };
  return (
    <>
      <span className="text-1xl font-bold text-indigo-900">
        الشهادات حسب الجنس
      </span>
      <div className="pt-7">
        <Doughnut data={chartData} options={options} />
      </div>
    </>
  );
};
DoughnutChart.propTypes = {
  chartData: PropTypes.object,
};
export default DoughnutChart;
