import React from "react";
import PropTypes from "prop-types";
const CountryNumber = ({ country }) => {
  return (
    <div>
      <span className="font-bold text-indigo-900 text-1xl">
        عدد الجامعات حسب البلد
      </span>
      <div className="grid gap-4 pt-2" data-testid="country">
        {country?.map((element) => (
          <div className="flex" key={element}>
            <span className="font-bold text-black">
              {element[0]} : <span>{element[1]}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
CountryNumber.propTypes = {
  country: PropTypes.array,
};
export default CountryNumber;
