import moment from "moment";
import { convertNumbers2English } from "./convert";
import readDateFromLeftToRight from "./dateUtils/readDateFromLeftToRight";

const isNotEmptyAndContainsOnlyLetters = (value) => {
  const string = /[\u0600-\u06FF]/;
  const letters = /^[A-Za-z\s]*$/;
  return !!((letters.test(value) || string.test(value)) && value.length > 1);
};
const isValidNumber = (value) => {
  const hasNumber = /^[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

  return !!hasNumber.test(convertNumbers2English(value));
};
const isValidPhoneNumber = (value) => {
  const hasNumber = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
  if (hasNumber.test(convertNumbers2English(value)) && value.length > 6) {
    return true;
  } else {
    return false;
  }
};
const isValidDate = (value) => {
  return (
    moment(
      convertNumbers2English(value),
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-DD-MM",
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY") !== "Invalid date" ||
    moment(
      convertNumbers2English(readDateFromLeftToRight(value)),
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-DD-MM",
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY") !== "Invalid date"
  );
};
const isValidEmail = (value) => {
  let emailRegEx =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegEx.test(String(value).toLowerCase());
};
const isValidPassword = (value) => {
  if (value !== "") {
    const hasNumber = /\d/;
    const arabicNumber = /[٠-٩]/g;
    const string = /[\u0600-\u06FF]/;
    const str = /[a-zA-Z]/;
    if (
      (hasNumber.test(value) || arabicNumber.test(value)) &&
      value.length > 6 &&
      (str.test(value) || string.test(value))
    )
      return true;
  } else {
    return false;
  }
};
const isNotEmpty = (value) => {
  return !!value;
};
export {
  isValidDate,
  isNotEmptyAndContainsOnlyLetters,
  isValidNumber,
  isValidEmail,
  isValidPhoneNumber,
  isValidPassword,
  isNotEmpty,
};
