import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOADING_USER } from "../../store/features/userSlice/userSlice";
import { useSigninMutation } from "../../store/service/usersService";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import ForgotPassword from "./ForgotPassword";
import logo from "../../assets/logo.png";
import { mailIcon, passwordIcon } from "../../assets/svg/Index";
import ReCAPTCHA from "react-google-recaptcha";
function LoginPage() {
  let navigate = useNavigate();
  const [signin] = useSigninMutation();
  const [forgotPwdClicked, setForgotPwdClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  let dispatch = useDispatch();
  const signInAction = async () => {
    setIsLoading(true);
    const dataToSend = {
      email: email.toLocaleLowerCase(),
      password,
    };

    await signin(dataToSend).then((res) => {
      if (res.data) {
        setIsLoading(false);
        dispatch(
          LOADING_USER({
            token: res.data.token,
            accessToken: true,
            role: res.data.role,
            username: res.data.username,
            email: res.data.email,
            numero: res.data.numero,
            isAccepted: res.data.isAccepted,
          })
        );
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.role);

        navigate("/dashboard");
      } else {
        setIsLoading(false);
        setError(true);
      }
    });
  };

  const changeEmail = (value) => {
    setEmail(value);
  };
  const changePassword = (value) => {
    setPassword(value);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-start justify-start space-y-8 bg-white p-14">
        <img src={logo} alt="" className="mb-2 w-[290px] h-14" />
        {!forgotPwdClicked ? (
          <h1 className="font-bold text-indigo-900 lg:text-4xl md:text-4xl ">
            فضاء الجامعة
          </h1>
        ) : (
          <>
            <h1 className="text-4xl font-bold text-blue-900">
              هل نسيت كلمات السر
            </h1>
            <p className="text-gray-500">
              اكتب بريدك الالكتروني وسنرسل لك رابط تغير كلمة السر
            </p>
          </>
        )}

        {!forgotPwdClicked ? (
          <>
            <Input
              icon={mailIcon}
              type={"NO_CONTROL"}
              placeholder=" البريد الالكتروني"
              inputChange={changeEmail}
              value={email}
              setValid={setIsEmailValid}
            />
            <Input
              icon={passwordIcon}
              placeholder="كلمة العبور"
              password
              inputChange={changePassword}
              type={"PASSWORD_LOGIN"}
              value={password}
              setValid={setIsPasswordValid}
            />
            <ReCAPTCHA
              sitekey="6Lf8-UAnAAAAACtQLuQRiKgEWzwnio-P9b_vhMWU"
              onChange={() => setCaptcha(true)}
            />

            <MainButton
              title="دخول"
              action={signInAction}
              isLoading={isLoading}
              disabled={
                !isEmailValid || !isPasswordValid || password === "" || !captcha
              }
            />
            {error ? (
              <span className="font-bold text-red-500">
                بيانات الاعتماد غير صالحة
              </span>
            ) : (
              <></>
            )}
            <div className="flex items-center justify-center w-full text-2xl font-bold">
              <p>هل نسيت كلمة السّر ؟ </p>
              <p
                className="mr-2 text-blue-800 cursor-pointer"
                onClick={() => {
                  setForgotPwdClicked(true);
                }}
              >
                اضغط هنا .
              </p>
            </div>
          </>
        ) : (
          <ForgotPassword setForgotPwdClicked={setForgotPwdClicked} />
        )}
      </div>
    </>
  );
}

export default LoginPage;
