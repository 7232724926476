import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { ADD_FROM_CSV_DIPLOMAS } from "../../store/features/diplomasSlice/diplomasSlice";
import {
  isNotEmptyAndContainsOnlyLetters,
  isValidDate,
  isValidNumber,
} from "../../utils/Validators";

function UpdateOneDiploma() {
  const diploma = useSelector((state) => state.diploma);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nin, setNin] = useState("");
  const [type, setType] = useState("");
  const [speciality, setSpecialty] = useState("");
  const [year, setYear] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [signatureDate, setSignatureDate] = useState("");
  const [honors, setHonors] = useState("");
  const [average, setAverage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * @function updatedDiploma
   * @description this function to update diploma
   *
   * @return
   */
  const updatedDiploma = () => {
    let diplomasTable = [...diploma.diplomasToAdd];
    let updatedDiploma = {
      firstName: firstName,
      lastName: lastName,
      nin: nin,
      type: type,
      speciality: speciality,
      honors: honors,
      approvalDate: approvalDate,
      signatureDate: signatureDate,
      average: average,
      year: year,
    };

    diplomasTable[diploma.currentDiploma.key] = updatedDiploma;
    dispatch(ADD_FROM_CSV_DIPLOMAS({ diplomasToAdd: diplomasTable }));
  };

  /**
   * @function initializeDiplomaData
   * @description this function to initialze diplomas data
   *
   * @param {*} currentDiploma
   *
   * @return
   */
  const initializeDiplomaData = (currentDiploma) => {
    setFirstName(currentDiploma?.firstName || "");
    setLastName(currentDiploma?.lastName || "");
    setNin(currentDiploma?.nin || "");
    setType(currentDiploma?.type || "");
    setSpecialty(currentDiploma?.speciality || "");
    setYear(currentDiploma?.year.toString() || "");
    setApprovalDate(currentDiploma?.approvalDate || "");
    setAverage(
      currentDiploma?.average?.toString() !== undefined
        ? currentDiploma?.average?.toString()
        : ""
    );
    setHonors(currentDiploma?.honors || "");
    setSignatureDate(currentDiploma?.signatureDate || "");
  };

  useEffect(() => {
    const { currentDiploma } = diploma;
    let isCurrentDiplomaHasProperties =
      Object.keys(currentDiploma).length !== 0;
    if (isCurrentDiplomaHasProperties) {
      initializeDiplomaData(currentDiploma);
    } else {
      navigate("/add_diplomas");
    }
  }, []);

  useEffect(() => {
    let _isDisabled =
      type === "" ||
      !isNotEmptyAndContainsOnlyLetters(speciality) ||
      !isNotEmptyAndContainsOnlyLetters(honors) ||
      !isValidNumber(average) ||
      !isValidNumber(year) ||
      !isValidDate(signatureDate) ||
      !isValidDate(approvalDate);
    setIsDisabled(_isDisabled);
  }, [
    type,
    speciality,
    honors,
    average,
    year,
    signatureDate,
    approvalDate,
    isDisabled,
  ]);
  return (
    <div className="flex flex-col p-[3%]">
      <h1 className="text-2xl text-[#25396f] font-semibold">تعديل شهادة </h1>
      <div className="bg-white rounded">
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">المعرف الوطني </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={nin}
                inputChange={setNin}
                value={nin}
                disabled
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> نوع الشهادة </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={type}
                inputChange={setType}
                value={type}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> الاختصاص </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={speciality}
                inputChange={setSpecialty}
                value={speciality}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> تاريخ المصادقة </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={approvalDate}
                inputChange={setApprovalDate}
                value={approvalDate}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> تاريخ الإمضاء </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={signatureDate}
                inputChange={setSignatureDate}
                value={signatureDate}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> السنة الجامعية </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={year}
                inputChange={setYear}
                value={year}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> الملاحظة </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={honors}
                inputChange={setHonors}
                value={honors}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> المعدّل / المجموع </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={average}
                inputChange={setAverage}
                value={average}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <MainButton
                title="إضافة"
                action={() => {
                  updatedDiploma();
                  navigate("/add_diplomas");
                }}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateOneDiploma;
