import React from "react";

import DiplomasTable from "./DiplomasTable";

const DiplomaPendingList = () => {
  return (
    <div>
      <div className="flex flex-col justify-end mt-10 px-[3%] ">
        <h1 className="mb-2 text-2xl font-bold text-blue-900">
          طلبات إضافة شهادات بانتظار التأكيد
        </h1>
        <div className="max-w-[auto] flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md">
          <div className="w-full">
            <div>
              <h5 className="px-6 font-bold text-blue-900 ">قائمة الشهادات</h5>
              <DiplomasTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiplomaPendingList;
