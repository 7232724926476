import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { GET_AGENTS } from "../../store/features/studentsSlice/studentsSlice";
import { LOADING_USER } from "../../store/features/userSlice/userSlice";
import { useGetAgentsByRespMutation } from "../../store/service/diplomasService";

import { useGetUserMutation } from "../../store/service/userService";

import AgentTable from "./AgentTable";

const AgentList = () => {
  const dispatch = useDispatch();
  const [getAgents] = useGetAgentsByRespMutation();
  const [agents, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [getUser] = useGetUserMutation();

  /**
   * @function _getUser
   * @description this function to get User
   *
   * @return
   */
  const _getUser = () => {
    getUser().then((res) => {
      dispatch(
        LOADING_USER({
          role: res.data.data.role.name,
          username: res.data.data.username,
          email: res.data.data.email,
          universityName: res.data.universityName,
          speciality: res.data.data.speciality,
          numero: res.data.data.numero,
          isAccepted: res.data.data.IsAccepted,
        })
      );
    });
  };

  useEffect(() => {
    getAgents().then((res) => {
      setAgent(res.data.data);
      setLoading(true);
      dispatch(GET_AGENTS({ agent: res.data.data }));
    });
    _getUser();
  }, []);
  return (
    <>
      {user.isAccepted ? (
        <>
          <div className="flex flex-col justify-end mt-10 px-[3%] ">
            <span className="mb-4 text-2xl font-bold text-indigo-900">
              الرجاء الانتظار ، نحن بصدد إجراء العملية
            </span>
            <Spinner />
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="flex flex-col justify-end mt-10 px-[3%] ">
              <h1 className="mb-2 text-2xl font-bold text-indigo-900">
                طلبات إضافة شهادات بانتظار التأكيد
              </h1>

              {agents?.length !== 0 ? (
                <>
                  <div className="max-w-[80vw] flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md">
                    {loading ? (
                      <div className="w-full">
                        <div>
                          <h3 className="mb-2 font-bold text-blue-900 text-1xl">
                            عرض إجمالي
                          </h3>
                        </div>

                        <AgentTable agents={agents} />
                      </div>
                    ) : (
                      <div className="h-[300px] flex items-center justify-center ">
                        <Spinner />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AgentList;
