import React from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const DiplomaPdfWithCloseButton = ({ setOpen, pdf }) => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative w-auto mx-auto my-6 max-w-auto">
          {/*content*/}
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
            {/*header*/}

            {/*body*/}
            <div className="relative flex-auto p-4">
              <Document file={pdf} className="w-full rounded-t-xl">
                <Page pageNumber={1} />
              </Document>
            </div>
            {/*footer*/}

            <div className="flex items-center justify-center p-2 border-t border-solid rounded-b border-slate-200">
              <button
                onClick={() => setOpen(false)}
                className="bg-[#e6eaee] text-[#1a2026]  leading-normal font-normal uppercase text-base px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                غلق النافذة
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-25 z-2"></div>
    </>
  );
};
DiplomaPdfWithCloseButton.propTypes = {
  setOpen: PropTypes.func,
  pdf: PropTypes.string,
};
export default DiplomaPdfWithCloseButton;
