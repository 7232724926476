import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { read, utils, writeFile, set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);

import { useDispatch, useSelector } from "react-redux";
import { ADD_FROM_CSV } from "../../store/features/studentsSlice/studentsSlice";
import { convertNumbers2English } from "../../utils/convert";
import FileInput from "../../components/input/FileInput";
import { excelSerialNumberToDate } from "../../utils/dateUtils/convertDateTimeStambyFormatDate";
import { toastErrorServer } from "../../components/toast/Toast";

function AddStudentsUploader({ setError, setOpen }) {
  const dispatch = useDispatch();
  const studentsFromRedux = useSelector(
    (state) => state.students.studentsToAdd
  );

  /**
   * @function onClear
   * @description this function to clear metadata Student
   *
   * @return
   */
  function onClear() {
    let emptyStudents = [];
    dispatch(ADD_FROM_CSV({ studentsToAdd: emptyStudents }));
  }

  /**
   * @function isHeaderValid
   * @description this function to check Header file xlsx
   *
   * @param(header)
   *
   * @return
   */
  function isHeaderValid(header) {
    return (
      header.includes("firstName") &&
      header.includes("lastName") &&
      header.includes("nin") &&
      header.includes("dateOfBirth")
    );
  }

  /**
   * @function isFieldNotValid
   * @description this function to valide the field
   *
   * @param(field)
   *
   * @return
   */
  function isFieldNotValid(field) {
    return field === undefined;
  }

  /**
   * @function isDateNotValid
   * @description this function to check field is date or not
   *
   * @param(field)
   *
   * @return
   */
  function isDateNotValid(field) {
    const dateObj = moment(
      convertNumbers2English(field.toString()),
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "MM-DD-YYYY"
    );
    const formattedDate = dateObj.format("DD/MM/YYYY");
    if (formattedDate !== "Invalid date") {
      return false;
    }
    return (
      field !== undefined &&
      moment(
        convertNumbers2English(excelSerialNumberToDate(field).toString()),
        "DD-MM-YYYY",
        "YYYY-MM-DD",
        "MM-DD-YYYY"
      ).format("DD-MM-YYYY") === "Invalid date"
    );
  }

  /**
   * @function openModal
   * @description this function to open Model and  delete Student
   *
   * @param(key)
   * @param(student)
   *
   * @return
   */
  function deleteDuplicatedNin(data) {
    return data.reduce((acc, current) => {
      const x = acc.find((item) => item.nin === current.nin);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  /**
   * @function dispatchStudents
   * @description this function to dispatch Students in redux
   *
   * @param(students)
   *
   * @return
   */
  function dispatchStudents(students) {
    let studentsWithNoDuplicatedNins = deleteDuplicatedNin(students);
    dispatch(ADD_FROM_CSV({ studentsToAdd: studentsWithNoDuplicatedNins }));
  }

  /**
   * @function showErrorsModal
   * @description this function to open Modal Error
   *
   * @param(errorObj)
   *
   * @return
   */
  function showErrorsModal(errorObj) {
    setError(errorObj);
    setOpen(true);
  }

  /**
   * @function extractErrors
   * @description this function to extract the errors in the modal
   *
   * @param(results)
   * @param(index)
   *
   * @return
   */
  function extractErrors(results, index) {
    let colonnes = [];
    let ligne = index + 1;
    if (isFieldNotValid(results[index]["firstName"])) {
      colonnes.push("الاسم الأول");
      colonnes.push(" , ");
    }
    if (isFieldNotValid(results[index]["lastName"])) {
      colonnes.push("اسم العائلة");
      colonnes.push(" , ");
    }
    if (isFieldNotValid(results[index]["nin"])) {
      colonnes.push(" المعرّف");
      colonnes.push(" , ");
    }
    if (isDateNotValid(results[index]["dateOfBirth"])) {
      colonnes.push(" تاريخ الولادة");
      colonnes.push(" , ");
    }

    return { ligne, colonnes };
  }

  /**
   * @function writeDate
   * @description this function to check date is valid or not
   *
   * @param(date)
   *
   * @return
   */
  function writeDate(date) {
    const dateObj = moment(
      convertNumbers2English(date.toString()),
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "MM-DD-YYYY"
    );
    const formattedDate = dateObj.format("DD-MM-YYYY");
    return formattedDate;
  }

  /**
   * @function handleFileChange
   * @description this function to get the metadata in the file and check this metadata
   *
   * @param(file)
   *
   * @return
   */
  function handleFileChange(file) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, {
        type: "array",
        cellDates: true,
        cellNF: false,
        cellText: false,
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const results = utils.sheet_to_json(worksheet, { raw: true });
      let students = [];
      const header = utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];

      if (isHeaderValid(header)) {
        let errorObj = [];
        for (let index = 0; index < results.length; index++) {
          let isThereAnyError =
            isFieldNotValid(results[index]["firstName"]) ||
            isFieldNotValid(results[index]["lastName"]) ||
            isFieldNotValid(results[index]["nin"]) ||
            isDateNotValid(results[index]["dateOfBirth"]);
          if (isThereAnyError) {
            const { ligne, colonnes } = extractErrors(results, index);
            errorObj.push({
              ligne,
              colonnes,
            });
          } else {
            const studentUploader = {
              firstName: results[index]["firstName"],
              lastName: results[index]["lastName"],
              nin: results[index]["nin"].toString(),
              dateOfBirth: writeDate(
                excelSerialNumberToDate(results[index]["dateOfBirth"])
              ),
              countryOfBirth: results[index]["countryOfBirth"],
              sex: results[index]["sex"],
              nationalty: results[index]["nationalty"],
              phone: results[index]["phone"]?.toString(),
              email: results[index]["email"],
            };
            students.push(studentUploader);
          }
        }
        if (errorObj.length !== 0) {
          showErrorsModal(errorObj);
        }
        dispatchStudents(students);
      } else {
        toastErrorServer("الرجاء التثبت في المعطيات !");
      }
    };
    reader.readAsArrayBuffer(file);
  }

  /**
   * @function downloadFile
   * @description this function to download xlsx
   *
   * @return
   */
  function downloadFile() {
    let xlsData = [
      {
        firstName: "",
        lastName: "",
        nin: "",
        dateOfBirth: "",
        countryOfBirth: "",
        sex: "",
        nationalty: "",
        phone: "",
        email: "",
      },
    ];
    const worksheet = utils.json_to_sheet(xlsData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "data.xls");
  }

  return (
    <div className="flex flex-col items-center justify-end w-full p-4 rounded bg-blue-50">
      <p className="w-full text-right">
        قم برفع ملف اكسل Excel مثل هذا الأنموذج
        <span
          className="font-bold text-red-700 cursor-pointer "
          onClick={downloadFile}
        >
          (اضغط هنا)
        </span>
        يتضمن بيانات الطلبة. مع العلم أن هذه الأعمدة الأربعة (First name / Last
        Name / Nin / Date Of Birth) هي فقط الضرورية أما باقي الأعمدة فهي
        اختيارية لإكمال عملية تسجيل الطلبة.
      </p>
      <div className="w-full">
        <FileInput
          onChange={handleFileChange}
          OnClear={onClear}
          isUploaded={studentsFromRedux.length > 0}
        />
      </div>
    </div>
  );
}
AddStudentsUploader.propTypes = {
  setOpen: PropTypes.func,
  setError: PropTypes.func,
};
export default AddStudentsUploader;
