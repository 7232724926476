import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { SELECT_DIPLOMA } from "../../store/features/diplomasSlice/diplomasSlice";
import DiplomaPdfWithCloseButton from "../../components/modal/Diploma/DiplomaPdfWithCloseButton";
import { toastSucces } from "../../components/toast/Toast";

function AddDiplomasTable({ setOpenModal, setKey, setDiplomaToDelete }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const diploma = useSelector((state) => state.diploma);
  const file = useSelector((state) => state.diploma.files);
  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState("");

  let data = [];
  for (let index = 0; index < diploma?.diplomasToAdd.length; index++) {
    const diplomaData = {
      id: diploma?.diplomasToAdd[index].nin,
    };
    const metadata = diploma?.diplomasToAdd[index];
    let pdfFileName = null;
    for (let index = 0; index < file?.length; index++) {
      if (diplomaData.id === file[index].fileName) {
        pdfFileName = file[index];
      }
    }
    const newData = {
      metadata,
      pdf: pdfFileName,
    };
    data.push(newData);
  }

  /**
   * @function openModal
   * @description this function to open Modal
   *
   * @return
   */
  const openModal = (key, diploma) => {
    setOpenModal((prev) => !prev);
    setKey(key);
    setDiplomaToDelete(diploma);
  };

  /**
   * @function fileOpen
   * @description this function to open pdf in modal
   *
   * @return
   */
  const fileOpen = (filename) => {
    setOpen(!open);
    setPdf(filename);
  };

  /**
   * @function checkPdfLength
   * @description this function to check Pdf Length with metadata
   *
   * @return
   */
  const checkPdfLength = () => {
    if (file?.length > 0 && diploma?.diplomasToAdd.length > 0) {
      if (file?.length === diploma?.diplomasToAdd.length - 1) {
        toastSucces("تمت العملية بنجاح !");
      }
    }
  };

  useEffect(() => {
    checkPdfLength();
  }, [file]);

  return (
    <div className="flex flex-col justify-start ">
      <h1 className="p-4 "> قائمة بيانات الشهادات والملفات</h1>
      <div className="relative p-2 overflow-x-auto  sm:rounded-lg h-[auto]">
        <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-blue-900 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                الطالب(ة)
              </th>
              <th scope="col" className="px-6 py-3">
                المعرف الوطني
              </th>
              <th scope="col" className="px-6 py-3">
                نوع الشهادة
              </th>
              <th scope="col" className="px-6 py-3">
                الاختصاص
              </th>
              <th scope="col" className="px-6 py-3">
                الملاحظة
              </th>
              <th scope="col" className="px-6 py-3">
                تاريخ المصادقة
              </th>
              <th scope="col" className="px-6 py-3">
                تاريخ الإمضاء
              </th>
              {file?.length == 0 && Object.keys(file?.length - 1) == 0 ? (
                <th scope="col" className="px-6 py-3"></th>
              ) : (
                <>
                  <th scope="col" className="px-6 py-3">
                    PDF
                  </th>
                </>
              )}
              <th scope="col" className="px-6 py-3">
                تعديل
              </th>
            </tr>
          </thead>

          <>
            <tbody className="overflow-x-hidden h-10vh">
              {data &&
                data
                  .map((diploma, key) => {
                    return (
                      <tr
                        key={diploma?.metadata.nin}
                        className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                          (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
                        }`}
                      >
                        <td className="px-6 py-2">
                          {diploma?.metadata.firstName}{" "}
                          {diploma?.metadata.lastName}
                        </td>
                        <td className="px-6 py-2"> {diploma?.metadata.nin}</td>
                        <td className="px-6 py-2"> {diploma?.metadata.type}</td>
                        <td className="px-6 py-2">
                          {diploma?.metadata.speciality}
                        </td>
                        <td className="px-6 py-2">
                          {" "}
                          {diploma?.metadata.honors}
                        </td>
                        <td className="px-6 py-2">
                          {diploma?.metadata.approvalDate}
                        </td>
                        <td className="px-6 py-2">
                          {diploma?.metadata.signatureDate}
                        </td>
                        {diploma?.pdf && diploma?.pdf !== undefined ? (
                          <>
                            <td className="px-6 py-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                                onClick={() => fileOpen(diploma?.pdf?.file)}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                            </td>
                          </>
                        ) : (
                          <td className="px-6 py-2"></td>
                        )}

                        <td className="flex items-center justify-center px-2 py-2 ">
                          <button
                            onClick={() => {
                              dispatch(
                                SELECT_DIPLOMA({
                                  currentDiploma: {
                                    ...diploma?.metadata,
                                    key,
                                  },
                                })
                              );
                              navigate("/update_one_diploma");
                            }}
                            className="p-1 mx-1 text-xs font-bold text-white bg-blue-400 rounded w-fi h-fit"
                          >
                            تعديل
                          </button>

                          <button
                            onClick={() => {
                              openModal(key, diploma);
                            }}
                            className="p-1 mx-1 text-xs font-bold text-white bg-red-400 rounded w-fi h-fit"
                          >
                            حذف
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  .filter((student, key) => {
                    return key !== 0;
                  })}
            </tbody>
          </>
        </table>
        {open ? (
          <DiplomaPdfWithCloseButton pdf={pdf} setOpen={setOpen} />
        ) : null}
      </div>
    </div>
  );
}
AddDiplomasTable.propTypes = {
  isLoadingData: PropTypes.bool,
  setOpenModal: PropTypes.func,
  setKey: PropTypes.func,
  setDiplomaToDelete: PropTypes.func,
};
export default AddDiplomasTable;
