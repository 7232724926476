/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { useAddStudentsMutation } from "../../store/service/studentsService";
import { convertNumbers2English } from "../../utils/convert";
import { excelSerialNumberToDate } from "../../utils/dateUtils/convertDateTimeStambyFormatDate";
import readDateFromLeftToRight from "../../utils/dateUtils/readDateFromLeftToRight";

function AddOneStudent() {
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [CID, setCID] = useState("");
  const [birthdate, setbirthdate] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [nationalty, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [numTel, setNumTel] = useState("");
  const [birthdateValid, setbirthdateValid] = useState(false);

  let [addStudents] = useAddStudentsMutation();

  let navigate = useNavigate();

  /**
   * @function setIsUniversityNameValid
   *
   * @return true
   */
  const setIsUniversityNameValid = () => {
    return true;
  };

  /**
   * @function writeDate
   * @description this function to update diploma
   *
   * @param(date)
   *
   * @return
   */
  function writeDate(date) {
    const dateObj = moment(
      convertNumbers2English(date.toString()),
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "MM-DD-YYYY"
    );
    const formattedDate = dateObj.format("DD-MM-YYYY");
    return formattedDate;
  }

  /**
   * @function updateStudent
   * @description this function to update diploma
   *
   * @return
   */
  const updateStudent = () => {
    if (CID === "" || name === "" || surName === "") {
      toastErrorServer(
        "المعطيات ناقصة ، الرجاء إدخال على الأقل الإسم وإسم العائلة والمعرف"
      );
    } else {
      addStudents({
        students: [
          {
            firstName: name,
            lastName: surName,
            nin: CID,
            dateOfBirth:
              writeDate(excelSerialNumberToDate(birthdate)) === "Invalid date"
                ? readDateFromLeftToRight(convertNumbers2English(birthdate))
                : writeDate(excelSerialNumberToDate(birthdate)),
            countryOfBirth: birthPlace,
            sex: gender,
            nationalty: nationalty,
            phone: numTel,
            email: email,
          },
        ],
      }).then((res) => {
        if (res.data.notAddedStudents.length !== 0) {
          toastErrorServer("هذا المعرف موجود ");
        } else {
          toastSucces("تمت العملية بنجاح !");
          setTimeout(() => {
            navigate("/student");
          }, 3000);
        }
      });
    }
  };

  useEffect(() => {
    console.log(birthdateValid);
  }, [birthdateValid]);

  return (
    <div className="flex flex-col p-[3%]">
      <h1 className="p-2 text-3xl font-bold text-indigo-900">إضافة طالب</h1>
      <div className="bg-white rounded">
        <div className="flex items-center justify-center w-full pt-8 h-fit">
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> الاسم الأول </p>
              <Input
                type={"NAME"}
                placeholder={"الاسم الأول "}
                inputChange={setName}
                value={name}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">اسم العائلة </p>
              <Input
                type={"NAME"}
                placeholder={"اسم العائلة "}
                inputChange={setSurName}
                value={surName}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">المعرف الوطني </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={"المعرف الوطني"}
                inputChange={setCID}
                value={CID}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full p-2 h-fit">
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> تاريخ الولادة </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={" تاريخ الولادة (dd-mm-yyyy)"}
                inputChange={setbirthdate}
                value={birthdate}
                setValid={setbirthdateValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> مكان الولادة </p>
              <Input
                type={"NAME"}
                placeholder={"مكان الولادة "}
                inputChange={setBirthPlace}
                value={birthPlace}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> الجنس </p>
              <Input
                type={"NAME"}
                placeholder={"الجنس"}
                inputChange={setGender}
                value={gender}
                setValid={setIsUniversityNameValid}
                isSelect
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full p-2 h-fit">
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> الجنسية </p>
              <Input
                type={"NAME"}
                placeholder={"الجنسية"}
                inputChange={setNationality}
                value={nationalty}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> البريد الالكتروني </p>
              <Input
                type={"EMAIL"}
                placeholder={"البريد الالكتروني "}
                inputChange={setEmail}
                value={email}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900"> الهاتف </p>
              <Input
                type={"NUMBER"}
                placeholder={"الهاتف"}
                inputChange={setNumTel}
                value={numTel}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full">
          <div className="w-40 p-2">
            <MainButton
              title="إضافة"
              disabled={
                name === "" ||
                surName === "" ||
                CID === "" ||
                (!birthdateValid && birthdate === "")
              }
              action={() => {
                updateStudent();
              }}
            />
          </div>
          <div className="w-40 p-2">
            <button
              type="submit"
              onClick={() => {
                navigate("/add_student");
              }}
              className={
                "w-full h-10 text-black bg-[#dadada] shadow-lg rounded-md hover:bg-[#e7e7e7]"
              }
            >
              الرجوع إلى القائمة
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOneStudent;
