import React, { Fragment } from "react";

import PropTypes from "prop-types";
import RejectedDiplomaUpdateModal from "./RejectedDiplomaUpdateModal";
import ModalLayout from "../../../layouts/ModalLayout";
import DiplomaContentModal from "./DiplomaContentModal";
const RejectedDiplomaModal = ({
  diploma,
  item,
  handleOpenModal,
  showUpdate,
  setShowUpdate,
  openModal,
  updateDipl,
  setOpenModal,
  setLoading,
  _deleteDiploma,
  filtered,
}) => {
  return (
    <ModalLayout>
      <div className="relative flex flex-col bg-white border-0 rounded-lg shadow-sm outline-none w-fit focus:outline-none">
        {/*header*/}
        <div className="flex items-start justify-between p-3 bg-blue-900 border-b border-solid rounded-t border-slate-200">
          <h3 className="text-2xl font-semibold text-white">
            {showUpdate ? "تعديل الشهادة" : "تفاصيل الشهادة"}
          </h3>
        </div>
        {/*body*/}
        <div className="relative flex-auto p-4">
          {showUpdate === false ? (
            <Fragment>
              <DiplomaContentModal
                item={item}
                diploma={diploma}
                updateDipl={updateDipl}
                handleOpenModal={handleOpenModal}
              />
            </Fragment>
          ) : (
            <Fragment>
              <RejectedDiplomaUpdateModal
                diploma={diploma}
                item={item}
                handleOpenModal={handleOpenModal}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
                setLoading={setLoading}
                filtered={filtered}
              />
            </Fragment>
          )}
        </div>
        {/*footer*/}
        {showUpdate ? (
          <></>
        ) : (
          <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-slate-200">
            <button
              onClick={() => setOpenModal(!openModal)}
              className="bg-[#e6eaee] text-[#1a2026]  leading-normal font-normal uppercase text-base px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            >
              غلق النافذة
            </button>
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
              type="button"
              onClick={() => {
                _deleteDiploma({ item });
              }}
            >
              حذف
            </button>
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-600 rounded shadow outline-none active:bg-green-800 hover:shadow-lg focus:outline-none"
              type="button"
              onClick={() => {
                setShowUpdate(!showUpdate);
              }}
            >
              تعديل
            </button>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};
RejectedDiplomaModal.propTypes = {
  diploma: PropTypes.object,
  item: PropTypes.object,
  updateDipl: PropTypes.func,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleOpenModal: PropTypes.func,
  setOpenModal: PropTypes.func,
  setLoading: PropTypes.func,
  openModal: PropTypes.bool,
  _deleteDiploma: PropTypes.func,
  filtered: PropTypes.array,
};
export default RejectedDiplomaModal;
