/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { toastErrorServer } from "../../components/toast/Toast";
import {
  ADD_FILES,
  ADD_PDFS,
} from "../../store/features/diplomasSlice/diplomasSlice";

function AddDiplomasPDFUploader() {
  let dispatch = useDispatch();
  const diploma = useSelector((state) => state.diploma);

  const [file, setFile] = useState(null);
  let filesName = [];
  const files = useSelector((state) => state.diploma.files);

  /**
   * @function handleChange
   * @description this function to verify pdf with metadata
   *
   * @return
   */
  const handleChange = async (file) => {
    setFile(file);
    if (file?.length !== null) {
      for (const element of file) {
        let filename = {
          file: URL.createObjectURL(element),
          fileName: element.name.slice(0, element.name.length - 4),
          filename: element.name,
        };
        for (let i = 1; i < diploma?.diplomasToAdd?.length + 1; i++) {
          if (filename.fileName === diploma?.diplomasToAdd[i]?.nin) {
            filesName.push(filename);
          }
        }
      }
      if (filesName.length === 0) {
        toastErrorServer(`الملفات غير مطبقة`);
      }
    }
    const formData = new FormData();
    Array.from(file).forEach((diplome) => {
      return formData.append("PDFdiplomas", diplome);
    });
    let addedPdfs = [...files, ...filesName];
    dispatch(ADD_PDFS({ pdfs: formData }));
    dispatch(ADD_FILES({ files: addedPdfs }));
  };

  useEffect(() => {
    handleChange();
  }, [file]);

  /**
   * @function removePdf
   * @description this function to remove all the pdf
   *
   * @return
   */
  const removePdf = () => {
    setFile(null);
    dispatch(ADD_FILES({ files: [] }));
  };

  const fileTypes = ["pdf"];
  return (
    <div className="flex flex-col items-center justify-end w-full p-4 rounded bg-blue-50">
      <p className="w-full text-right">
        في مرحلة ثانية قم برفع عدّة ملفات "PDF" مع تسمية كل ملف بالمعرّف الوطني
        للطالب حتى يتمّ ربطه مع بيانات الشهادات.
      </p>
      {files?.length > 0 && (
        <>
          <div
            onClick={() => {
              removePdf();
            }}
            className="flex items-center justify-center w-5 h-5 text-center text-white bg-red-500 rounded-full cursor-pointer"
          >
            x
          </div>
        </>
      )}
      <div className="w-full h-auto overflow-auto rounded-full ">
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          multiple
          label={"أفلت ملفات PDF في هذا المستطيل أو اضغط هنا "}
          classes="fileUploader"
          // eslint-disable-next-line react/no-children-prop
          children={
            <div
              className={
                "flex items-center justify-center w-full p-3 bg-gray-300   "
              }
            >
              {file ? (
                <>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div
                      className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: "100%" }}
                    >
                      100%
                    </div>
                  </div>
                </>
              ) : (
                <p>أفلت ملفات PDF في هذا المستطيل أو اضغط هنا </p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}

export default AddDiplomasPDFUploader;
