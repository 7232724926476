import React from "react";
import PropTypes from "prop-types";
import { diplomasIcon, loginIcons, studentsIcon } from "../../assets/svg/Index";
const Card = ({ data }) => {
  return (
    <div className="flex gap-5 justify-between ">
      <div className="flex p-4  my-2 bg-white w-1/3 rounded-xl">
        <div
          className={`w-10 h-10 mr-8 bg-[#57caeb] rounded-md min-w-10 text-center flex items-center justify-center text-white`}
        >
          {studentsIcon}
        </div>
        <div className="flex flex-col items-start justify-start mr-4">
          <span className="text-[#7c8db5]">عدد الطلبة</span>
          <span className="font-extrabold leading-5 text-base text-[#25396f]">
            {data.nbStudents}
          </span>
        </div>
      </div>
      <div className="flex p-4  my-2 bg-white w-1/3 rounded-xl">
        <div
          className={`w-10 h-10 mr-8 bg-[#5ddab4] rounded-md min-w-10 text-center flex items-center justify-center text-white`}
        >
          {diplomasIcon}
        </div>
        <div className="flex flex-col items-start justify-start mr-4">
          <span className="text-[#7c8db5]">عدد الشهادات</span>
          <span className="font-extrabold leading-5 text-base text-[#25396f]">
            {data.nbDiplomas}
          </span>
        </div>
      </div>
      <div className="flex p-4  my-2 bg-white w-1/3 rounded-xl">
        <div
          className={`w-10 h-10 mr-8 bg-[#9694ff] rounded-md min-w-10 text-center flex items-center justify-center text-white`}
        >
          {loginIcons}
        </div>
        <div className="flex flex-col items-start justify-start mr-4">
          <span className="text-[#7c8db5]">الزيارات اليومية</span>
          <span className="font-extrabold leading-5 text-base text-[#25396f]">
            {data.nbLogin}
          </span>
        </div>
      </div>
    </div>
  );
};
Card.propTypes = {
  data: PropTypes.object,
};
export default Card;
