import React, { useState } from "react";
import PropTypes from "prop-types";
import NavigationBar from "../coreUI/NavigationBar/NavigationBar";
import ProtectedRoute from "../utils/ProtectedRouter";
import Header from "./Header";
import { menuIcon } from "../assets/svg/Index";

function Layout({ children }) {
  const [show, setShow] = useState(true);
  return (
    <ProtectedRoute>
      <div className="flex items-start justify-between w-full h-full ">
        <div
          className={
            show
              ? "hidden lg:flex h-[100vh] p-10 bg-white   "
              : " lg:flex h-[100vh] p-10 bg-white      "
          }
        >
          <NavigationBar />
        </div>

        <main className="w-full h-full min-h-screen bg-[#f2f7ff] p-[1rem] ">
          <div
            className="  flex flex-col justify-end pr-[5%] lg:hidden"
            onClick={() => setShow(!show)}
          >
            {menuIcon}
          </div>
          <Header />
          {children}
        </main>
      </div>
    </ProtectedRoute>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
