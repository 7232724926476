/**
 * @function exports.convertNumbers2English
 * @description convert number arabic to English
 *
 * @param {*} string
 *
 * @returns
 */
const convertNumbers2English = (string) => {
  return string
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (c) {
      return c.charCodeAt(0) - 1632;
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (c) {
      return c.charCodeAt(0) - 1776;
    })
    .replaceAll("/", "-");
};

/**
 * @function exports.convertSlash2Dash
 * @description convert slash to dash
 *
 * @param {*} string
 *
 * @returns
 */
const convertSlash2Dash = (string) => {
  return string
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (c) {
      return c.charCodeAt(0) - 1632;
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (c) {
      return c.charCodeAt(0) - 1776;
    })
    .replaceAll("/", "-")
    .replaceAll("-", "/");
};

export { convertNumbers2English, convertSlash2Dash };
