import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useUpdateDiplomasMutation } from "../../store/service/diplomasService";
import { useDispatch, useSelector } from "react-redux";
import SpinnerSmal from "../../components/spinner/SpinnerSmal";

import { LOADING } from "../../store/features/diplomasSlice/diplomasSlice";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { toast } from "react-toastify";
const AgentDetails = ({ agent, setFiltered }) => {
  const navigate = useNavigate();
  const [updateDiploma] = useUpdateDiplomasMutation();
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const diploma = useSelector((state) => state.diploma.diplomas);
  const agentItem = useSelector((state) => state.students.agent);
  useEffect(() => {
    if (agent.username === undefined || agentItem?.createdBy?.length === 0) {
      navigate("/listsAgent");
    }
  }, []);

  const dispatch = useDispatch();
  const updateDipl = (diploma, decision) => {
    if (decision === "accept") {
      setIsLoadingAccept(true);
      updateDiploma({
        decision: "accept",
        diplomas: diploma,
        createdBy: agentItem[0].createdBy,
      }).then((res) => {
        if (!res.error) {
          setFiltered([]);
          setIsLoadingAccept(false);
          toastSucces("تم قبول الشهادات");
          dispatch(LOADING({ loading: false }));
          setTimeout(() => {
            toast.success(
              "هذا العملية تحت المعالجة، قد تستغرق وقتًا، سوف نعيد توجيهك إلى لوحة القيادة.",
              {
                position: "top-left",
                theme: "colored",
              }
            );
            navigate("/dashboard");
            dispatch(LOADING({ loading: true }));
          }, 3000);
        } else {
          toastErrorServer(`هنالك خطأ من السرفر`);
        }
      });
    } else {
      updateDiploma({
        decision: "rejected",
        diplomas: diploma,
        createdBy: agentItem[0].createdBy,
      }).then((res) => {
        if (!res.error) {
          setFiltered([]);
          setIsLoading(false);
        } else {
          toastErrorServer(`هنالك خطأ من السرفر`);
        }
      });
    }
  };
  return (
    <>
      <div className="relative h-auto p-4 overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3 text-[#607080]">
                الموظف
              </th>
              <th scope="col" className="px-4 py-3 text-[#607080]">
                السنة الجامعية
              </th>
              <th scope="col" className="px-4 py-3 text-[#607080]">
                نوع الشهادة
              </th>
              <th scope="col" className="px-4 py-3 text-[#607080]">
                الاختصاص
              </th>
              <th scope="col" className="px-4 py-3 text-[#607080]">
                عدد الشهادات
              </th>
              <th scope="col" className="px-4 py-3 text-[#607080]">
                تاريخ الإضافة
              </th>
              <th scope="col" className="px-4 py-3 text-[#607080]"></th>
            </tr>
          </thead>
          <tbody className="overflow-x-hidden h-10vh">
            <tr className="border-b dark:bg-gray-900 dark:border-gray-700 bg-[#f2f2f2]">
              <td className="px-4 py-2 text-[#607080]">{agent?.username}</td>
              <td className="px-4 py-2 text-[#607080]">{agent?.year}</td>
              <td className="px-4 py-2 text-[#607080]">{agent?.type}</td>
              <td className="px-4 py-2 text-[#607080]">{agent?.speciality}</td>
              <td className="px-4 py-2 text-[#607080]">{agent?.count}</td>
              <td className="px-4 py-2 text-[#607080]">
                {agent?.insertionDate === "Invalid date"
                  ? null
                  : agent?.insertionDate?.substr(0, 10)}
              </td>

              <td className="flex items-center justify-center px-6 py-2">
                <button
                  className="p-1 px-4 mx-1 text-xs font-semibold text-white bg-[#198754] rounded w-fi h-fit"
                  onClick={() => {
                    updateDipl(diploma, "accept");
                  }}
                >
                  {isLoadingAccept ? (
                    <SpinnerSmal />
                  ) : (
                    <span> اعتماد الجميع</span>
                  )}
                </button>
                <button
                  className="p-1 px-4 mx-1 text-xs font-semibold text-white bg-[#dc3545] rounded w-fi h-fit"
                  onClick={() => {
                    updateDipl(diploma, "rejected");
                  }}
                >
                  {isLoading ? <SpinnerSmal /> : <span> إلغاء الجميع</span>}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
AgentDetails.propTypes = {
  agent: PropTypes.object,
  setFiltered: PropTypes.func,
};
export default AgentDetails;
