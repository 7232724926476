/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  useGetStudentbyIdMutation,
  useUpdateStudentMutation,
} from "../../store/service/studentsService";
import { GET_STUDENTS } from "../../store/features/studentsSlice/studentsSlice";

import Input from "../../components/input/Input";
import MainButton from "../../components/button/MainButton";

import { toastSucces } from "../../components/toast/Toast";
import SelectGender from "../../components/input/SelectGender";

const UpdateStudent = () => {
  const params = useParams();
  const [student, setStudent] = useState([]);
  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [lastName, setLastName] = useState("");
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [nationalty, setNationalty] = useState("");
  const [isNationaltyValid, setIsNationaltyValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(false);
  const [nin, setNin] = useState(0);
  const [sex, setSex] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [isCountryOfBirthValid, setIsCountryOfBirthValid] = useState(false);
  const [getStudentById] = useGetStudentbyIdMutation();
  const [updateStudent] = useUpdateStudentMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getStudentById(params.id).then((res) => {
      setStudent(res.data.data);
      setIsLoading(true);
    });
  }, []);
  useEffect(() => {
    setName(isLoading || student?.firstName ? student?.firstName : "");
    setLastName(isLoading || student?.lastName ? student?.lastName : "");
    setEmail(isLoading || student?.email ? student?.email : "");
    setNationalty(isLoading || student?.nationalty ? student?.nationalty : "");
    setPhone(isLoading || student?.phone ? student?.phone : "");
    setDateOfBirth(
      isLoading || student?.dateOfBirth ? student?.dateOfBirth : ""
    );

    setCountryOfBirth(
      isLoading || student?.countryOfBirth ? student?.countryOfBirth : ""
    );
    setNin(isLoading || student?.nin ? student?.nin : "");
    setSex(isLoading || student?.sex ? student?.sex : "");
  }, [isLoading]);
  const navigate = useNavigate();
  const update = () => {
    let data = {
      name,
      lastName,
      nin,
      dateOfBirth,
      countryOfBirth,
      sex,
      nationalty,
      email,
      phone,
    };
    setStudent(data);
    updateStudent({
      student: {
        firstName: name,
        lastName: lastName,
        nin: nin,
        dateOfBirth: dateOfBirth,
        countryOfBirth: countryOfBirth,
        sex: sex,
        nationalty: nationalty,
        email: email,
        phone: phone,
      },
    });
    dispatch(GET_STUDENTS({ students: [student] }));
    toastSucces("تمت العملية بنجاح !");
    navigate("/student");
  };

  return (
    <div className="flex flex-col p-20 rounded-md">
      <h1 className="pb-2 text-2xl text-[#25396f] text-bold">تعديل طالب</h1>
      <div className="bg-white rounded">
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> الاسم الأول </p>
              <Input
                type={"NAME"}
                placeholder={name}
                inputChange={setName}
                value={name}
                setValid={setIsNameValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">اسم العائلة </p>
              <Input
                type={"NAME"}
                placeholder={lastName}
                inputChange={setLastName}
                value={lastName}
                setValid={setIsLastNameValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> الهاتف </p>
              <Input
                type={"NUMBER"}
                placeholder={phone}
                inputChange={setPhone}
                value={phone}
                setValid={setIsPhoneValid}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> تاريخ الولادة </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={dateOfBirth}
                inputChange={setDateOfBirth}
                value={dateOfBirth}
                setValid={setIsDateOfBirthValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> مكان الولادة </p>
              <Input
                type={"NAME"}
                placeholder={countryOfBirth}
                inputChange={setCountryOfBirth}
                value={countryOfBirth}
                setValid={setIsCountryOfBirthValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> الجنس </p>
              <SelectGender value={sex} setSex={setSex} />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> الجنسية </p>
              <Input
                type={"NAME"}
                placeholder={nationalty}
                inputChange={setNationalty}
                value={nationalty}
                setValid={setIsNationaltyValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]"> البريد الالكتروني </p>
              <Input
                type={"EMAIL"}
                placeholder={email}
                inputChange={setEmail}
                value={email}
                setValid={setIsEmailValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <MainButton
              title="تعديل"
              action={() => {
                update();
              }}
              disabled={
                !isNameValid ||
                !isLastNameValid ||
                (!isPhoneValid && phone !== "") ||
                !isDateOfBirthValid ||
                (!isCountryOfBirthValid && countryOfBirth !== "") ||
                (!isNationaltyValid && nationalty !== "") ||
                (!isEmailValid && email !== "")
              }
            />
          </div>
          <div className="p-2 lg:w-40">
            <button
              type="submit"
              onClick={() => {
                navigate("/student");
              }}
              className={
                "w-full h-10 text-black bg-[#dadada] shadow-lg rounded-md hover:bg-[#e7e7e7]"
              }
            >
              الرجوع إلى القائمة
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateStudent;
