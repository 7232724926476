import jwtDecode from "jwt-decode";

const VerifyToken = () => {
  const token = localStorage.getItem("token");
  if (jwtDecode(token).exp * 1000 < Date.now()) {
    return false;
  } else {
    return true;
  }
};

export default VerifyToken;
