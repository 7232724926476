import { Fragment } from "react";
import ContentModal from "../../../pages/DiplomasList/ContentModal";
import Update from "../../../pages/DiplomasList/Update";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";

const DiplomaModalWithCloseAndDeleteButtons = ({
  diploma,
  item,
  handleOpen,
  showUpdate,
  setShowUpdate,
  setLoading,
  updateDipl,
  setOpen,
}) => {
  return (
    <ModalLayout>
      <div className="relative w-auto mx-auto my-6 max-w-auto">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-3 bg-blue-900 border-b border-solid rounded-t border-slate-200">
            <h3 className="text-2xl font-semibold text-white">
              {showUpdate ? "تعديل الشهادة" : "تفاصيل الشهادة"}
            </h3>
          </div>
          {/*body*/}
          <div className="relative flex-auto p-4">
            {showUpdate === false ? (
              <Fragment>
                <ContentModal
                  item={item}
                  diploma={diploma}
                  updateDipl={updateDipl}
                  handleOpen={handleOpen}
                />
              </Fragment>
            ) : (
              <Fragment>
                <Update
                  diploma={diploma}
                  item={item}
                  handleOpen={handleOpen}
                  showUpdate={showUpdate}
                  setShowUpdate={setShowUpdate}
                  setLoading={setLoading}
                />
              </Fragment>
            )}
          </div>
          {/*footer*/}
          {showUpdate ? (
            <></>
          ) : (
            <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-slate-200">
              <button
                onClick={() => setOpen(!open)}
                className="bg-[#e6eaee] text-[#1a2026]  leading-normal font-normal uppercase text-base px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                غلق النافذة
              </button>
              <button
                className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
                type="button"
                onClick={() => {
                  updateDipl({ item });
                }}
              >
                إلغاء
              </button>
            </div>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};
DiplomaModalWithCloseAndDeleteButtons.propTypes = {
  diploma: PropTypes.object,
  item: PropTypes.object,
  updateDipl: PropTypes.func,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleOpen: PropTypes.func,
  setOpen: PropTypes.func,
  setLoading: PropTypes.func,
};
export default DiplomaModalWithCloseAndDeleteButtons;
