/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useUpdateDiplomaMutation } from "../../../store/service/diplomasService";
import { toastSucces } from "../../toast/Toast";
import ModalLayout from "../../../layouts/ModalLayout";
import Input from "../../input/Input";
const RejectedDiplomaUpdateModal = ({
  diploma,
  item,
  handleOpenModal,
  setShowUpdate,
  showUpdate,
  setLoading,
  filtered,
}) => {
  const setIsUniversityNameValid = () => {
    return true;
  };

  const [type, setType] = useState("");
  const [firstName] = useState(item?.student?.firstName);
  const [lastName] = useState(item?.student?.lastName);
  const [dateOfBirth] = useState(item?.student?.dateOfBirth);
  const [countryOfBirth] = useState(item?.student?.countryOfBirth);
  const [honors, setHonors] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [year, setYear] = useState("");
  const [average, setAverage] = useState(0);
  const [signatureDate, setSignatureDate] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [idDiploma, setIdDiploma] = useState(item._id);
  const [hashIpfs] = useState(item?.hashIpfs);
  useEffect(() => {
    setHonors(item?.honors ? item?.honors : "");
    setType(item?.type ? item?.type : "");

    setSpeciality(item?.speciality ? item?.speciality : "");
    setYear(item?.year ? item?.year : "");
    setAverage(item?.average ? item?.average : "");
    setSignatureDate(item?.signatureDate ? item?.signatureDate : "");
    setApprovalDate(item?.approvalDate ? item?.approvalDate : "");
    setIdDiploma(item?._id ? item?._id : "");
  }, []);
  const [updateDiploma] = useUpdateDiplomaMutation();

  const update = () => {
    updateDiploma({
      diploma: {
        type: type,
        idDiploma: idDiploma,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        countryOfBirth: countryOfBirth,
        honors: honors,
        speciality: speciality,
        average: average,
        year: year,
        signatureDate: signatureDate,
        approvalDate: approvalDate,
        hashIpfs: hashIpfs,
        status: "pending",
      },
    });
    setShowUpdate(!showUpdate);
    handleOpenModal();
    setLoading(false);
    toastSucces("تمت العملية بنجاح !");
  };
  const setApprovalDateValid = () => {
    return true;
  };
  const setSignatureDateValid = () => {
    return true;
  };

  return (
    <ModalLayout>
      <div className="relative flex flex-col w-fit bg-white border-0 rounded-lg outline-none focus:outline-none">
        <div className="bg-white rounded">
          <div className="flex items-center justify-center w-full pt-8 h-fit"></div>
          <div className="flex items-center justify-center w-full p-2 h-fit">
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">* الاختصاص</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={speciality}
                  inputChange={setSpeciality}
                  value={speciality}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">نوع الشهادة</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={type}
                  inputChange={setType}
                  value={type}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900"> الملاحظة </p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={honors}
                  inputChange={setHonors}
                  value={honors}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">السنة الجامعية</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={year}
                  inputChange={setYear}
                  value={year}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full p-2 h-fit">
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">المعدّل</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={average.toString()}
                  inputChange={setAverage}
                  value={average}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">تاريخ التوقيع</p>
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={approvalDate}
                  inputChange={setApprovalDate}
                  value={approvalDate}
                  setValid={setApprovalDateValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">تاريخ المصادقة </p>
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={signatureDate}
                  inputChange={setSignatureDate}
                  value={signatureDate}
                  setValid={setSignatureDateValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-4 border-t border-solid rounded-b border-slate-200">
          <button
            className="bg-[#e6eaee] text-[#1a2026]  leading-normal font-normal uppercase text-base px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => handleOpenModal()}
          >
            غلق النافذة
          </button>
          <button
            className="px-10 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-800 rounded shadow outline-none active:bg-green-900 hover:shadow-lg focus:outline-none"
            type="button"
            onClick={() => {
              update();
            }}
          >
            تأكيد
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};
RejectedDiplomaUpdateModal.propTypes = {
  diploma: PropTypes.object,
  item: PropTypes.object,
  handleOpenModal: PropTypes.func,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setLoading: PropTypes.func,
  filtered: PropTypes.array,
};
export default RejectedDiplomaUpdateModal;
