import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import admin from "../assets/admin.png";
const Header = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-end">
      <div className="flex justify-end items-center w-1/4 gap-3 mt-4 px-[3%] cursor-pointer">
        <span
          className="text-1xl font-semibold text-[#25396f]"
          onClick={() => {
            navigate("/settings");
          }}
        >
          {user.username}
        </span>

        <img
          src={admin}
          className="w-10 h-10"
          onClick={() => {
            navigate("/settings");
          }}
        />
      </div>
    </div>
  );
};

export default Header;
