import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { useForgetPasswordMutation } from "../../store/service/usersService";
import { useNavigate } from "react-router-dom";
import { mailIcon } from "../../assets/svg/Index";

function ForgotPassword({ setForgotPwdClicked }) {
  const [forgetPassword] = useForgetPasswordMutation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const requestPassword = () => {
    setIsLoading(true);
    const data = {
      email,
    };
    forgetPassword(data).then((res) => {
      setTimeout(() => {
        if (res.data) {
          setIsLoading(false);
          setEmail("");
          setForgotPwdClicked(false);
        }
      }, 2000);
    });
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  }, []);
  return (
    <div className="w-full h-full ">
      <div className="my-8">
        <Input
          icon={mailIcon}
          placeholder=" البريد الالكتروني"
          value={email}
          inputChange={setEmail}
          type={"EMAIL"}
          setValid={setIsEmailValid}
        />
      </div>
      <div className="my-8">
        <MainButton
          title="إرسال "
          action={requestPassword}
          disabled={!isEmailValid}
          isLoading={isLoading}
        />
      </div>
      <div className="flex items-center justify-center w-full mt-20 text-2xl font-bold">
        <p> الرجوع إلى صفحة </p>
        <p
          className="mr-2 text-blue-800 cursor-pointer"
          onClick={() => {
            setForgotPwdClicked(false);
          }}
        >
          تسجيل الدخول .
        </p>
      </div>
      :
    </div>
  );
}
ForgotPassword.propTypes = {
  setForgotPwdClicked: PropTypes.func.isRequired,
};

export default ForgotPassword;
