/****************************************************************/
/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GET_STUDENTS } from "../../store/features/studentsSlice/studentsSlice";
import sortBy from "../../utils/sortyBy";
import { DownArrow, UpArrow } from "../../assets/svg/Index";

const Table = ({
  filtered,
  role,
  navigate,
  handleOpen,
  setUserId,
  perPage,
  currentPage,
  setFiltered,
}) => {
  const dispatch = useDispatch();
  const [nomClicked, setNomClicked] = useState(false);
  const [prenomClicked, setPrenomClicked] = useState(false);
  const [birthDateClicked, setBirthDateClicked] = useState(false);

  const onNomCLick = () => {
    setNomClicked(!nomClicked);
    const sorted = [...filtered].sort(sortBy("firstName", nomClicked));
    setFiltered(sorted);
    dispatch(GET_STUDENTS({ students: sorted }));
  };
  const onPrenomCLick = () => {
    setPrenomClicked(!prenomClicked);
    const sorted = [...filtered].sort(sortBy("lastName", prenomClicked));
    setFiltered(sorted);
    dispatch(GET_STUDENTS({ students: sorted }));
  };
  const onDateOfBirthClick = () => {
    setBirthDateClicked(!birthDateClicked);
    if (birthDateClicked) {
      const sorted = [...filtered].sort((a, b) => {
        return moment(b.dateOfBirth).isBefore(moment(a.dateOfBirth)) ? 1 : -1;
      });
      setFiltered(sorted);
      dispatch(GET_STUDENTS({ students: sorted }));
    } else {
      const sorted = [...filtered].sort((a, b) => {
        return moment(a.dateOfBirth).isBefore(moment(b.dateOfBirth)) ? 1 : -1;
      });
      setFiltered(sorted);
      dispatch(GET_STUDENTS({ students: sorted }));
    }
  };

  const perpage = parseInt(perPage);

  return (
    <table className="w-full text-sm text-right text-[#607080] dark:text-gray-400">
      <thead className="text-xs text-[#607080] uppercase  dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th
            scope="col"
            className="flex items-center  px-6 py-2 cursor-pointer"
            onClick={onNomCLick}
          >
            الاسم الأول
            {nomClicked ? (
              <div className="cursor-pointer ">
                <DownArrow />
              </div>
            ) : (
              <div className="cursor-pointer ">
                <UpArrow />
              </div>
            )}
          </th>
          <th
            scope="col"
            className="px-6 py-1 cursor-pointer"
            onClick={onPrenomCLick}
          >
            <div className="flex items-center ">
              <span> اسم العائلة</span>
              {prenomClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-6 py-2">
            المعرف الوطني
          </th>
          <th
            scope="col"
            className="px-6 py-2 cursor-pointer"
            onClick={onDateOfBirthClick}
          >
            <div className="flex items-center ">
              <span> تاريخ الولادة</span>
              {birthDateClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>

          <th scope="col" className="px-6 py-1">
            مكان الولادة
          </th>
          <th scope="col" className="px-6 py-2">
            الجنس
          </th>
          <th scope="col" className="px-6 py-2">
            الجنسية
          </th>
          <th scope="col" className="px-6 py-2">
            الهاتف
          </th>
          <th scope="col" className="px-6 py-2">
            البريد الالكتروني
          </th>

          <th scope="col" className="px-6 py-2"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh ">
        {filtered !== [] ? (
          filtered
            ?.slice(currentPage * perpage - perpage, currentPage * perpage)
            .map((student, key) => (
              <tr
                key={key}
                className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                  (key + 1) % 2 !== 0 ? `bg-[#f2f2f2]` : `bg-white`
                }`}
              >
                <td className="px-6 py-1 ">{student?.firstName}</td>
                <td className="px-6 py-1 ">{student?.lastName}</td>
                <td className="px-6 py-1 ">{student?.nin}</td>
                <td className="px-6 py-1 ">
                  {student?.dateOfBirth === "Invalid date"
                    ? null
                    : student?.dateOfBirth}
                </td>
                <td className="px-6 py-1 ">{student?.countryOfBirth}</td>
                <td className="px-6 py-1 ">{student?.sex}</td>
                <td className="px-6 py-1 ">{student?.nationalty}</td>
                <td className="px-6 py-1 ">{student?.phone}</td>
                <td className="px-6 py-1 ">{student?.email}</td>
                <td className="flex items-center justify-center px-6 py-2">
                  {role === "ROLE_RESP_UNI" ? (
                    <button
                      onClick={() => {
                        navigate(`/updatestudent/${student?.nin}`);
                      }}
                      className="px-2 py-1  mx-1 font-semibold text-white bg-[#435ebe] rounded text-md w-fi h-fit"
                    >
                      تعديل
                    </button>
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={() => {
                      navigate(`/ficheStudent/${student?.nin}`);
                    }}
                    className="px-2 py-1 mx-1 font-semibold text-white bg-[#0dcaf0] rounded text-md w-fi h-fit"
                  >
                    الحساب
                  </button>

                  {role === "ROLE_RESP_UNI" ? (
                    <>
                      <button
                        onClick={() => {
                          handleOpen();
                          setUserId(student?.nin);
                        }}
                        className="px-2 py-1  mx-1 font-semibold text-white bg-[#dc3545] rounded text-md w-fi h-fit"
                      >
                        حذف
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td>No entries found!</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  filtered: PropTypes.array,
  perPage: PropTypes.number,
  role: PropTypes.string,
  navigate: PropTypes.func,
  handleOpen: PropTypes.func,
  setUserId: PropTypes.func,
  setFiltered: PropTypes.func,
};
export default Table;
