import { convertNumbers2English } from "./convert";

/**
 * @function filterByCriteria
 * @description this function search agent by criteria
 *
 * @param {*} agentsList
 * @param {*} searchBy
 * @param {*} searchInput
 *
 * @returns List Filter by criteria
 */
const filterByCriteria = (agentsList, searchBy, searchInput) => {
  const _filtered = agentsList.filter(
    (agent) =>
      (searchBy.includes("speciality") &&
        agent.speciality?.toLowerCase().includes(searchInput.toLowerCase())) ||
      (searchBy.includes("email") &&
        agent.email?.toLowerCase().includes(searchInput.toLowerCase())) ||
      (searchBy.includes("numero") &&
        agent.numero?.includes(convertNumbers2English(searchInput))) ||
      (searchBy.includes("universityName") &&
        agent.universityName
          ?.toLowerCase()
          .includes(searchInput.toLowerCase())) ||
      (searchBy.includes("country") &&
        agent.country?.toLowerCase().includes(searchInput.toLowerCase())) ||
      (searchBy.includes("role") &&
        agent.role?.toLowerCase().includes(searchInput.toLowerCase()))
  );

  return _filtered;
};

/**
 * @function filterList
 * @description this function is to filter the list User by 2 method
 *
 * @param {*} selectedFilterInputs
 * @param {*} setAgent
 * @param {*} agentsList
 * @param {*} searchInput
 * @param {*} setSearchInput
 *
 * @returns list filter
 */
const filterList = (
  selectedFilterInputs,
  setAgent,
  agentsList,
  searchInput,
  setSearchInput
) => {
  if (selectedFilterInputs.length > 0) {
    for (let i = 0; i < selectedFilterInputs.length; i++) {
      if (
        selectedFilterInputs[i].includes("email") ||
        selectedFilterInputs[i].includes("numero") ||
        selectedFilterInputs[i].includes("universityName") ||
        selectedFilterInputs[i].includes("speciality") ||
        selectedFilterInputs[i].includes("country") ||
        selectedFilterInputs[i].includes("role")
      ) {
        const filteredSearchInputs = filterByCriteria(
          agentsList,
          selectedFilterInputs,
          searchInput
        );
        setAgent(filteredSearchInputs);
      }
    }
  } else if (setSearchInput !== "") {
    const _filtered = agentsList?.filter((agent) =>
      agent?.username?.toLowerCase().includes(searchInput.toLowerCase())
    );
    setAgent(_filtered);
  }
};

export { filterList, filterByCriteria };
