import React, { useId } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_AGENTS } from "../../store/features/diplomasSlice/diplomasSlice";
const AgentTable = ({ agents }) => {
  const navigate = useNavigate();
  const disptach = useDispatch();
  const userId = useId();

  /**
   * @function getAgentByiD
   * @description this function to get Agent By Id
   *
   * @return
   */
  const getAgentByiD = (
    id,
    username,
    count,
    year,
    type,
    speciality,
    insertionDate
  ) => {
    navigate(`/diplomas_not_confirm/${id}`);
    disptach(
      GET_AGENTS({
        agent: { username, count, year, type, speciality, insertionDate },
      })
    );
  };

  return (
    <div className="relative h-auto overflow-x-auto rounded-md sm:rounded-lg">
      <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400 ">
        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" className="px-2 py-2">
              الموظف
            </th>
            <th scope="col" className="px-2 py-2">
              السنة الجامعية
            </th>
            <th scope="col" className="px-2 py-2">
              نوع الشهادة
            </th>
            <th scope="col" className="px-2 py-2">
              الاختصاص
            </th>
            <th scope="col" className="px-2 py-2">
              عدد الشهادات
            </th>
            <th scope="col" className="px-2 py-2">
              تاريخ الإضافة
            </th>
            <th scope="col" className="px-2 py-2"></th>
          </tr>
        </thead>
        <tbody className="overflow-x-hidden h-10vh">
          {agents?.map((agent, key) => (
            <tr
              key={userId}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
              }`}
            >
              <td className="px-2 py-2">{agent?.createdBy?.username}</td>
              <td className="px-2 py-2">{agent?.year}</td>
              <td className="px-2 py-2">{agent?.type}</td>
              <td className="px-2 py-2">{agent?.speciality}</td>
              <td className="px-2 py-2">{agent?.count}</td>
              <td className="px-2 py-2">
                {agent?.insertionDate === "Invalid date"
                  ? null
                  : agent?.insertionDate?.substr(0, 10)}
              </td>
              <td className="px-2 py-2">
                <button
                  className="p-1 px-3 mx-1 text-xs font-semibold text-white bg-green-700 rounded w-fi h-fit"
                  onClick={() =>
                    getAgentByiD(
                      agent?.createdBy?._id,
                      agent?.createdBy?.username,
                      agent?.count,
                      agent?.year,
                      agent?.type,
                      agent?.speciality,
                      agent?.insertionDate
                    )
                  }
                >
                  اطّلاع
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
AgentTable.propTypes = {
  agents: PropTypes.array,
};
export default AgentTable;
