import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  useDeleteStudentMutation,
  useGetStudentsMutation,
} from "../../store/service/studentsService";
import { useSelector, useDispatch } from "react-redux";
import { GET_STUDENTS } from "../../store/features/studentsSlice/studentsSlice";

import Table from "./Table";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import Pagination from "../../components/pagination/Pagination";

import Spinner from "../../components/spinner/Spinner";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastSucces } from "../../components/toast/Toast";
import { convertNumbers2English, convertSlash2Dash } from "../../utils/convert";
import DeleteStudentWithIDModal from "../../components/modal/Student/DeleteStudentWithIDModal";
const animatedComponents = makeAnimated();
const StudentsTable = () => {
  const role = localStorage.getItem("role");
  const [getStudents] = useGetStudentsMutation();
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const disptach = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [searchBy, setSearchBy] = useState([]);
  const [pageOne, setPageOne] = useState(false);
  useEffect(() => {
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      getStudents().then((res) => {
        disptach(GET_STUDENTS({ students: res.data.data }));
        if (search === "" && searchBy.length == 0) {
          setFiltered(res.data.data);
          setLoading(true);
        }
      });
      setTimeout(() => {
        setLoading(true);
      }, 3000);
    }
  }, [loading]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteStudent] = useDeleteStudentMutation();
  const students = useSelector((state) => state.students.students);

  const searchby = () => {
    const _filtered = students.filter(
      (student) =>
        (searchBy.includes("nin") &&
          student.nin.includes(convertNumbers2English(search))) ||
        (searchBy.includes("email") &&
          student.email.toLowerCase().includes(search.toLowerCase())) ||
        (searchBy.includes("phone") &&
          student.phone.includes(convertNumbers2English(search))) ||
        (searchBy.includes("dateOfBirth") &&
          student.dateOfBirth.includes(convertSlash2Dash(search))) ||
        (searchBy.includes("countryOfBirth") &&
          student.countryOfBirth
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (searchBy.includes("sex") &&
          student.sex.toLowerCase().includes(search.toLowerCase())) ||
        (searchBy.includes("nationalty") &&
          student.nationalty.toLowerCase().includes(search.toLowerCase()))
    );
    setFiltered(_filtered);
  };

  const filterage = () => {
    if (searchBy.length > 0) {
      for (let i = 0; i < searchBy.length; i++) {
        if (
          searchBy[i].includes("email") ||
          searchBy[i].includes("nin") ||
          searchBy[i].includes("dateOfBirth") ||
          searchBy[i].includes("phone") ||
          searchBy[i].includes("countryOfBirth") ||
          searchBy[i].includes("sex") ||
          searchBy[i].includes("nationalty")
        ) {
          searchby();
        }
      }
    } else if (setSearch != "") {
      const _filtered = students.filter(
        (student) =>
          student.firstName.toLowerCase().includes(search.toLowerCase()) ||
          student.lastName.toLowerCase().includes(search.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  useEffect(() => {
    filterage();
  }, [search, currentPage, searchBy]);
  const handleChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSearchBy(array);
    });

    if (e?.length == []) {
      array.push("");
      setSearchBy([]);
    }
    setCurrentPage(1);
  };
  const handleChangeSelect = (e) => {
    setCurrentPage(1);
    setPerPage(e.target.value);
    setPageOne(!pageOne);
  };
  useEffect(() => {
    handleChangeSearch();
  }, [searchBy, loading]);
  //Delete Fonction
  const remove = (nin) => {
    deleteStudent(nin).then(() => {
      const _filtered = students.filter((student) => student.nin !== nin);
      dispatch(GET_STUDENTS({ students: _filtered }));
      setFiltered(_filtered);
      setOpen(!open);
      toastSucces("تمت العملية بنجاح !");
    });
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  const [userId, setUserId] = useState(0);

  const options = [
    { value: "nin", label: " المعرف الوطني" },
    { value: "countryOfBirth", label: " مكان الولادة	" },
    { value: "email", label: "البريد الالكتروني" },
    { value: "phone", label: " الهاتف" },
    { value: "nationalty", label: " الجنسية" },
    { value: "dateOfBirth", label: " تاريخ الولادة" },
    { value: "sex", label: " الجنس" },
  ];
  return (
    <>
      <div className="flex flex-col justify-start ">
        <h1 className="p-2 ">بيانات الطلبة</h1>
        <div className="flex items-center justify-between w-full gap-3 px-2">
          <input
            type={"text"}
            className="w-1/4 h-10 transition ease-in-out delay-200 border rounded focus:outline-blue-600 "
            placeholder="ابحث  ...!"
            value={search}
            onChange={handleChange}
          />
          <Select
            options={options}
            className="w-2/3"
            closeMenuOnSelect={false}
            placeholder="اختار ...!"
            components={animatedComponents}
            onChange={handleChangeSearch}
            styles={{ height: "3rem", borderWidth: "2px" }}
            isMulti
          />
          {filtered.length > 5 ? (
            <select
              className="w-1/12 h-10 border rounded focus:outline-blue-600 focus:border-none"
              onChange={handleChangeSelect}
              type="number"
              value={perPage}
            >
              <option type="number" value={5}>
                5
              </option>
              <option type="number" value={10}>
                10
              </option>
              <option type="number" value={20}>
                20
              </option>
              <option type="number" defaultValue={30}>
                30
              </option>

              <option type="number" value={50}>
                50
              </option>
            </select>
          ) : null}
        </div>

        <div className="relative w-full h-auto mt-8 overflow-x-auto sm:rounded-lg ">
          {loading ? (
            <Table
              filtered={filtered}
              students={students}
              role={role}
              navigate={navigate}
              handleOpen={handleOpen}
              setUserId={setUserId}
              currentPage={currentPage}
              perPage={perPage}
              setFiltered={setFiltered}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center ">
              <Spinner />
            </div>
          )}
        </div>
        <div className="flex justify-start">
          {filtered.length > 5 ? (
            <Pagination
              perPage={perPage}
              total={filtered.length}
              paginate={paginate}
              pageOne={pageOne}
            />
          ) : null}
        </div>
      </div>
      {open ? (
        <DeleteStudentWithIDModal
          remove={remove}
          userId={userId}
          setOpen={setOpen}
          open={open}
        />
      ) : null}
    </>
  );
};

export default StudentsTable;
