import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BarChart from "../../components/charts/Bar";
import CountryNumber from "../../components/charts/CountryNumber";
import DoughnutChart from "../../components/charts/Doughnut";

import {
  useGetDailyVisitsByUniversityMutation,
  useGetDailyVisitsMutation,
  useGetDiplomasBysexByUniOrRespMutation,
  useGetDiplomasBysexMutation,
  useGetNbrOfVistisByMonthAndUniversityMutation,
  useGetNbrOfVistisByMonthMutation,
  useGetNbStudentbyRespMutation,
  useGetNbStudentsMutation,
  useGetNumberDiplomasByadminMutation,
  useGetNumberDiplomasByRespMutation,
  useGetNumberOfUniversitiesByCountryMutation,
} from "../../store/service/dashboardService";

import { logout } from "../../utils/logout";
import VerifyToken from "../../utils/VerifyToken";
import Card from "./Card";

const Dashboard = () => {
  const [getNumberDiplomasByadmin] = useGetNumberDiplomasByadminMutation();
  const [getDiplomasbyresp] = useGetNumberDiplomasByRespMutation();
  const [getDiplomasBysex] = useGetDiplomasBysexMutation();
  const [getDiplomasBysexByUniOrResp] =
    useGetDiplomasBysexByUniOrRespMutation();

  const [getNbrOfVistisByMonth] = useGetNbrOfVistisByMonthMutation();
  const [getNbrOfVistisByMonthAndUniversity] =
    useGetNbrOfVistisByMonthAndUniversityMutation();
  const [getNbStudents] = useGetNbStudentsMutation();
  const [getNbStudentsByResp] = useGetNbStudentbyRespMutation();
  const [getDailyVisits] = useGetDailyVisitsMutation();
  const [getDailyVisitsByUniversity] = useGetDailyVisitsByUniversityMutation();
  const [getNumberOfUniversitiesByCountry] =
    useGetNumberOfUniversitiesByCountryMutation();
  const [nbDlp, setNbDlp] = useState(0);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [nbVist, setNbVist] = useState(0);
  const [results, setResult] = useState([]);
  const [nbStudents, setNbStudents] = useState(0);
  const [country, setCountry] = useState([]);
  const role = localStorage.getItem("role");

  const _getNumberStudent = () => {
    if (role === "ROLE_ADMIN_ALECSO") {
      getNbStudents().then((res) => {
        if (res.data.length !== 0) {
          setNbStudents(res.data.dataToSend);
        } else {
          setNbStudents(0);
        }
      });
    } else {
      getNbStudentsByResp().then((res) => {
        setNbStudents(res.data.dataToSend);
      });
    }
  };
  const _getDailyVisits = () => {
    if (role === "ROLE_ADMIN_ALECSO") {
      getDailyVisits().then((res) => {
        setNbVist(res.data.dataToSend);
      });
    } else {
      getDailyVisitsByUniversity().then((res) => {
        setNbVist(res.data.dataToSend);
      });
    }
  };
  const _getNumberOfUniversitiesByCountry = () => {
    getNumberOfUniversitiesByCountry().then((res) => {
      setCountry(res.data.dataToSend);
    });
  };
  const _getNumberDiplomasByadmin = () => {
    if (role === "ROLE_ADMIN_ALECSO") {
      getNumberDiplomasByadmin().then((res) => {
        if (res.data.length !== 0) {
          setNbDlp(res.data.dataToSend);
        } else {
          setNbDlp(0);
        }
      });
    } else {
      getDiplomasbyresp().then((res) => {
        if (res.data.length !== 0) {
          setNbDlp(res.data.dataToSend);
        } else {
          setNbDlp(0);
        }
      });
    }
  };
  const _getDiplomasBysex = async () => {
    if (role === "ROLE_ADMIN_ALECSO") {
      await getDiplomasBysex().then(async (res) => {
        setMale(await res.data.dataToSend.male);
        setFemale(await res.data.dataToSend.female);
      });
    } else {
      getDiplomasBysexByUniOrResp().then(async (res) => {
        setMale(await res.data.dataToSend.male);
        setFemale(await res.data.dataToSend.female);
      });
    }
  };

  const _getNbrOfVistisByMonth = async () => {
    if (role === "ROLE_ADMIN_ALECSO") {
      getNbrOfVistisByMonth().then(async (res) => {
        setResult(res.data.dataToSend);
      });
    } else {
      getNbrOfVistisByMonthAndUniversity().then((res) => {
        setResult(res.data.dataToSend);
      });
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      _getNumberDiplomasByadmin();
      _getDiplomasBysex();
      _getNbrOfVistisByMonth();
      _getNumberStudent();
      _getDailyVisits();
      _getNumberOfUniversitiesByCountry();
    }
  }, []);

  const data = {
    nbStudents: nbStudents,
    nbDiplomas: nbDlp,
    nbLogin: nbVist,
  };
  const chartData = {
    labels: ["ذكور", "إناث"],
    datasets: [
      {
        data: [male, female],
        backgroundColor: ["#2e458b", "#ff7682"],
        borderColor: "white",
        hoverOffset: 30,
        borderWidth: 2,
        cutout: "30%",
      },
    ],
  };

  const dataBarChart = {
    labels: results
      .map((res) => {
        return res.month;
      })
      .reverse(),

    datasets: [
      {
        backgroundColor: "#435ebe",
        borderColor: "rgb(67, 94, 190)",
        data: results
          .map((res) => {
            return res.verification;
          })
          .reverse(),
      },
    ],
  };

  return (
    <div className="w-full p-3">
      <h1 className="text-3xl font-bold text-[#25396f] pb-5">فضاء الجامعة</h1>
      <div className="flex flex-row items-start justify-between w-full gap-6">
        <div className="grid w-9/12 gap-6">
          <Card data={data} />
          {results.length > 0 ? <BarChart dataBarChart={dataBarChart} /> : null}
        </div>
        <div className="grid w-1/4 h-auto">
          <div className="p-3 my-2 bg-white rounded-xl">
            <CountryNumber country={country} />
          </div>
          {female > 0 && male > 0 ? (
            <div className="p-3 my-2 bg-white rounded-xl">
              <DoughnutChart chartData={chartData} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
