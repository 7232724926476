import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputErrorMessage from "./InputErrorMessage";
import {
  isNotEmpty,
  isNotEmptyAndContainsOnlyLetters,
  isValidDate,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
} from "../../utils/Validators";

const HIDE_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
    />
  </svg>
);
const SHOW_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
);

function Input({
  placeholder,
  icon,
  disabled,
  password,
  inputChange,
  value,
  type,
  setValid,
}) {
  const isInputValid = () => {
    switch (type) {
      case "EMAIL":
        return isValidEmail(value);
      case "PASSWORD":
        return isValidPassword(value);
      case "NAME":
        return isNotEmptyAndContainsOnlyLetters(value);
      case "University":
        return isNotEmpty(value);
      case "NUMBER":
        return isValidPhoneNumber(value);
      case "SPECIALITY":
        return isNotEmpty(value);
      case "NO_CONTROL":
        return isNotEmpty(value);
      case "DATE_FORMAT":
        return isValidDate(value);
      case "PASSWORD_LOGIN":
        return isNotEmpty(value);
      case "DISABLED":
        return true;
      default:
        return false;
    }
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const inputValue = event.target.value;
    inputChange(inputValue);
    setValid(isInputValid());
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    inputChange(value);
    setValid(isInputValid());
  }, [value]);

  return (
    <form className="w-full ">
      <div className="relative mb-6">
        <input
          data-testid="input-component"
          disabled={disabled}
          type={password && !showPassword ? "password" : "text"}
          className={
            isInputValid() || value === ""
              ? "disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full pr-10 p-2.5"
              : "hover:outline-blue-400 border-red-400 disabled:bg-slate-200 text-ellipsis overflow-hidden focus:border-2 outline-blue-300/70    bg-white border-2  text-gray-900 text-sm rounded-md block w-full pr-10 p-2.5"
          }
          placeholder={placeholder ? placeholder : ""}
          onChange={handleInputChange}
          value={value}
          autoComplete={"true"}
        />

        <div
          className={`absolute inset-y-0 right-0 flex items-center justify-center pr-3 pointer-events-none ${
            !isInputValid() && value.length > 0 && "mb-4"
          }`}
        >
          {icon}
        </div>
        {type === "PASSWORD_LOGIN" && (
          <div
            className={`absolute inset-y-0 left-0 flex items-center justify-center pl-3  z-50 cursor-pointer `}
            onClick={handleShowPassword}
          >
            {showPassword ? SHOW_ICON : HIDE_ICON}
          </div>
        )}
        <div
          className={`absolute inset-y-0 right-0 flex items-center justify-center pr-3 pointer-events-none ${
            !isInputValid() && value.length > 0 && "mb-4"
          }`}
        >
          {icon}
        </div>
        {!isInputValid() && value.length > 0 && (
          <div data-testid="input-component-error-message">
            <InputErrorMessage type={type} />
          </div>
        )}
      </div>
    </form>
  );
}
Input.defaultProps = {
  setValid: () => {
    return true;
  },
  inputChange: () => {},
};
Input.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.object,
  value: PropTypes.string,
  inputChange: PropTypes.func,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  setValid: PropTypes.func,
};
export default Input;
