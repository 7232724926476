import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_USERS } from "../../store/features/studentsSlice/studentsSlice";
import {
  DownArrow,
  UpArrow,
  AgentIcon,
  EditIcon,
  RespIcon,
  CheckIcon,
  BlockIcon,
} from "../../assets/svg/Index";

const Table = ({
  agents,
  resendFunction,
  openModel,
  currentPage,
  perPage,
  setAgent,
}) => {
  const [nomClicked, setNomClicked] = useState(false);
  const [specialtyClicked, setSpecialtyClicked] = useState(false);
  const [universityNameClicked, setUniversityNameClicked] = useState(false);
  const [roleClicked, setRoleClicked] = useState(false);
  const [countryClicked, setCountryClicked] = useState(false);
  const dispatch = useDispatch();
  const sortBy = (field, status) => {
    return (a, b) => {
      if (a[field] > b[field]) {
        return status ? -1 : 1;
      }
      if (b[field] > a[field]) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };

  const onRoleClick = () => {
    setRoleClicked(!roleClicked);
    const sorted = [...agents].sort(sortBy("role", roleClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const onUniversityNameClick = () => {
    setUniversityNameClicked(!universityNameClicked);
    const sorted = [...agents].sort(
      sortBy("universityName", universityNameClicked)
    );
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const onSpecialtyClick = () => {
    setSpecialtyClicked(!specialtyClicked);
    const sorted = [...agents].sort(sortBy("speciality", specialtyClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };
  const onNomCLick = () => {
    setNomClicked(!nomClicked);
    const sorted = [...agents].sort(sortBy("username", nomClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const onCountryClick = () => {
    setCountryClicked(!countryClicked);
    const sorted = [...agents].sort(sortBy("country", countryClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const handleChange = (e, _value, email, id, value, status) => {
    switch (_value) {
      case "resend":
        resendFunction(email, _value, value);
        break;
      case "block":
        openModel(id, _value, value, status, e);
        break;
      case "delete":
        openModel(id, _value, value, e);
        break;
    }
    handleReset(e);
  };

  const handleReset = (e) => {
    e.target.value = "choix";
  };
  const navigate = useNavigate();
  return (
    <table className="z-10 w-full text-sm text-right text-gray-500 dark:text-gray-400 ">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th
            scope="col"
            className="flex items-center  px-6 py-2 cursor-pointer"
            onClick={onNomCLick}
          >
            <>
              <span>الاسم</span>

              {nomClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </>
          </th>
          <th scope="col" className="px-4 py-2">
            البريد الالكتروني
          </th>
          <th scope="col" className="px-4 py-2">
            الهاتف
          </th>
          <th
            scope="col"
            className="px-4 py-2 cursor-pointer"
            onClick={onUniversityNameClick}
          >
            <div className="flex items-center ">
              <span>اسم الجامعة </span>
              {universityNameClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-8 py-2 cursor-pointer"
            onClick={onRoleClick}
          >
            <div className="flex items-center ">
              <span> وظيفة </span>
              {roleClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-8 py-2 cursor-pointer"
            onClick={onCountryClick}
          >
            <div className="flex items-center ">
              <span> البلد </span>
              {countryClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          {localStorage.getItem("role") === "ROLE_RESP_UNI" ||
          localStorage.getItem("role") === "ROLE_ADMIN_ALECSO" ? (
            <th
              scope="col"
              className="px-4 py-2 cursor-pointer"
              onClick={onSpecialtyClick}
            >
              <div className="flex items-center ">
                <span>إدارة</span>
                {specialtyClicked ? (
                  <div className="cursor-pointer ">
                    <DownArrow />
                  </div>
                ) : (
                  <div className="cursor-pointer ">
                    <UpArrow />
                  </div>
                )}
              </div>
            </th>
          ) : (
            <></>
          )}
          <th scope="col" className="px-4 py-2">
            نشيط
          </th>
          <th scope="col" className="px-4 py-2"></th>
          <th scope="col" className="px-4 py-2"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh">
        {agents
          ?.slice(currentPage * perPage - perPage, currentPage * perPage)
          .map((agent, key) => (
            <tr
              key={key}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
              }`}
            >
              <td className="px-6 py-2">{agent?.username}</td>
              <td className="px-6 py-2">{agent?.email}</td>
              <td className="px-6 py-2">{agent?.numero}</td>
              <td className="px-6 py-2">{agent?.universityName}</td>

              <td className="px-6 py-2">
                {agent?.role === "مدير الجامعة" ? (
                  <span className="flex items-center justify-start gap-3">
                    <RespIcon />
                    مدير الجامعة
                  </span>
                ) : (
                  <span className="flex items-center justify-start gap-3">
                    <AgentIcon />
                    موظف
                  </span>
                )}
              </td>
              <td className="px-6 py-2">{agent.country}</td>
              <td className="px-6 py-2">
                {agent?.speciality ? agent?.speciality : "مدير الجامعة"}
              </td>
              <td className="px-6 py-2">
                {agent?.block === true ? <BlockIcon /> : <CheckIcon />}
              </td>
              <td className="flex justify-end px-6 py-2">
                <div>
                  <select
                    className={
                      "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-44 p-1 flex items-start justify-start "
                    }
                    onChange={(e) => {
                      handleChange(
                        e,
                        e.target.value,
                        agent?.email,
                        agent?.userId,
                        agent?.block
                      );
                    }}
                  >
                    <option selected={true} value="choix">
                      اختر ...
                    </option>
                    {agent?.status === "pending" ? (
                      <option value="resend">إعادة إرسال رابط التسجيل</option>
                    ) : null}
                    <option value="block">
                      {agent?.block === false ? (
                        <>منع المستخدم من الدخول </>
                      ) : (
                        <>إعادة تفعيل الحساب</>
                      )}
                    </option>
                    <option value="delete">حذف المستخدم</option>
                  </select>
                </div>
              </td>
              <td>
                <button
                  onClick={() => {
                    navigate(`/updateUser/${agent.userId}`);
                  }}
                >
                  <EditIcon />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  agents: PropTypes.array,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setAgentId: PropTypes.func,
  setAgent: PropTypes.func,
  openModel: PropTypes.func,
  resendFunction: PropTypes.func,
};
export default Table;
