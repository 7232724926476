import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";
const DeleteDiplomaModal = ({
  setOpenModal,
  openModal,
  deleteDiploma,
  DiplomaToDelete,
}) => {
  return (
    <ModalLayout>
      <div className="relative w-auto max-w-3xl mx-auto my-6">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-2 bg-blue-900 border-b border-solid rounded-t border-slate-200">
            <h3 className="text-3xl font-semibold text-white">حذف</h3>
          </div>
          {/*body*/}
          <div className="relative flex-auto p-4">
            <h2 className="mb-5 text-2xl text-black">
              أنت تؤكد حذف شهادة {DiplomaToDelete.metadata.firstName}{" "}
              {DiplomaToDelete.metadata.lastName}
            </h2>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
              onClick={() => {
                deleteDiploma();
              }}
            >
              نعم
            </button>
            <button
              onClick={() => setOpenModal(!openModal)}
              className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
            >
              لا
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
DeleteDiplomaModal.propTypes = {
  setOpenModal: PropTypes.func,
  openModal: PropTypes.bool,
  deleteDiploma: PropTypes.func,
  DiplomaToDelete: PropTypes.object,
};
export default DeleteDiplomaModal;
