import React from "react";
import PropTypes from "prop-types";

function InputErrorMessage({ type }) {
  const errorMessages = {
    // eslint-disable-next-line no-undef
    password: process.env.REACT_APP_PASSWORD,
    // eslint-disable-next-line no-undef
    number: process.env.REACT_APP_NUMBER,
    // eslint-disable-next-line no-undef
    email: process.env.REACT_APP_EMAIL,
    // eslint-disable-next-line no-undef
    name: process.env.REACT_APP_NAME,
    // eslint-disable-next-line no-undef
    date: process.env.REACT_APP_DATE,
  };

  return (
    <>
      {type === "PASSWORD" && (
        <div data-testid="PASSWORD">
          <span className="text-red-400">{errorMessages.password}</span>
        </div>
      )}

      {type === "NUMBER" && (
        <div data-testid="NUMBER">
          <span className="text-red-400"> {errorMessages.number}</span>
        </div>
      )}

      {type === "EMAIL" && (
        <div data-testid="EMAIL">
          <span className="text-red-400">{errorMessages.email}</span>
        </div>
      )}
      {(type === "NAME" || type === "University" || type === "SPECIALITY") && (
        <div data-testid="NAME">
          <span className="text-red-400">{errorMessages.name}</span>
        </div>
      )}
      {type === "DATE_FORMAT" && (
        <div data-testid="DATE_FORMAT">
          <span className="text-red-400">{errorMessages.date}</span>
        </div>
      )}
    </>
  );
}
InputErrorMessage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default InputErrorMessage;
