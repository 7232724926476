import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";
const AddDiplomaErrorModal = ({
  error,
  setOpen,
  nonExistingStudents,
  setError,
  errorObject,
  setErrorObject,
  setNonExistingStudents,
  errorObj,
}) => {
  const remove = () => {
    setError([]);
    setErrorObject([]);
    setOpen(false);
    errorObj.push();
    setNonExistingStudents([]);
  };

  return (
    <ModalLayout>
      <div className="relative w-[700px] mx-auto my-6 max-w-auto">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          <div className="flex items-start justify-between p-3 bg-red-900 border-b border-solid rounded-t border-slate-200">
            <h3 className="text-2xl font-semibold text-white">خطأ</h3>
          </div>

          <div className="relative p-4 flex-auto max-h-[60vh] overflow-y-auto ">
            <div className="pt-2 pb-3">
              {errorObject?.length !== 0 ? (
                <span className="pb-3 text-2xl font-semibold">
                  لم يقع احتساب المعرفات التالية لوجدها
                </span>
              ) : (
                <></>
              )}
              <div className="grid justify-start pt-4 pb-3 text-right">
                {errorObject?.map((e) => (
                  <div key={e} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-900">
                      المعرف : {e?.nin}
                    </span>
                  </div>
                ))}
              </div>
              {errorObject?.length !== 0 && error?.length !== 0 ? (
                <hr className="text-red-900" />
              ) : (
                <></>
              )}
            </div>

            <div className="pt-3">
              {error?.length !== 0 ? (
                <span className="pb-3 text-2xl font-semibold">
                  لم يقع احتساب الأسطر التالية لعدم صحتها
                </span>
              ) : (
                <></>
              )}

              <div className="grid justify-start pt-4 text-right">
                {error?.map((e) => (
                  <div key={e} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-900">
                      السطر : {e.ligne}
                    </span>
                    {e?.colonnes?.length > 0 ? (
                      <span className="font-semibold">
                        الخانات : {e.colonnes}
                      </span>
                    ) : null}
                    {e?.err?.length > 0 ? (
                      <span className="font-semibold">الخطأ : {e.err}</span>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>

            <div className="grid justify-start pt-4 text-right">
              {nonExistingStudents.length !== 0 && (
                <span>في الأسطر التالية ليس هنالك طلبة بالمعرف المعطى</span>
              )}

              {nonExistingStudents?.map((e) => (
                <div key={e} className="grid justify-start text-right">
                  <span className="font-semibold text-blue-700">
                    السطر : {e.ligne}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex justify-center pt-4">
              <span className="text-2xl"> يرجى التحقق من الأسطر</span>
            </div>
          </div>

          <div className="flex items-center justify-center p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-900 rounded shadow outline-none active:bg-gray-900 hover:shadow-lg focus:outline-none"
              onClick={() => remove()}
            >
              غلق النافذة
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
AddDiplomaErrorModal.propTypes = {
  setOpen: PropTypes.func,
  error: PropTypes.array,
  nonExistingStudents: PropTypes.array,
  setError: PropTypes.func,
  errorObject: PropTypes.array,
  setErrorObject: PropTypes.func,
  open: PropTypes.bool,
  errorObj: PropTypes.array,
  setNonExistingStudents: PropTypes.func,
};
export default AddDiplomaErrorModal;
