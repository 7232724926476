import moment from "moment";

const isSignatureDateBeforeApprovalDate = (approval, signature) => {
  console.log(
    "approval",
    approval,
    moment(approval, "DD-MM-YYYY"),
    "signature",
    signature,
    moment(signature, "DD-MM-YYYY"),
    moment(signature, "DD-MM-YYYY").diff(
      moment(approval, "DD-MM-YYYY"),
      "days"
    ) < 0
  );
  return (
    moment(signature, "DD-MM-YYYY").diff(
      moment(approval, "DD-MM-YYYY"),
      "days"
    ) < 0
  );
};

export { isSignatureDateBeforeApprovalDate };
