import React from "react";
import { Select, Option } from "@material-tailwind/react";
import PropTypes from "prop-types";

import { mapIcon } from "../../assets/svg/Index";
function SelectCountry({ value, setCountry }) {
  const listOfArabCountries = [
    "دولة فلسطين",
    " الإمارات العربية المتحدة",
    "البحرين",
    "الكويت",
    "السعودية",
    "اليمن",
    "قطر",
    "سلطنة عمان",
    "الأردن",
    "سوريا",
    "لبنان",
    "العراق",
    "مصر",
    "المغرب",
    "ليبيا",
    "تونس",
    "الجزائر",
    "السودان",
    "موريتانيا",
    " جيبوتي",
    " الصومال",
  ];
  const handleOnChange = (e) => {
    setCountry(e);
  };
  return (
    <form className="w-full ">
      <div className="relative mb-6">
        <Select
          defaultValue={""}
          variant="static"
          value={value}
          onChange={handleOnChange}
          className={
            "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-full pr-10 p-2.5 flex items-start justify-start "
          }
        >
          {listOfArabCountries.map((country) => {
            return (
              <Option key={country} value={country}>
                <span className="mr-4">{country}</span>
              </Option>
            );
          })}
        </Select>
        <div
          className={`absolute inset-y-0 right-0 flex items-center pr-3 mt-2 pointer-events-none  ${
            !value && "mb-2"
          }`}
        >
          {mapIcon}
        </div>
      </div>
    </form>
  );
}

SelectCountry.propTypes = {
  value: PropTypes.string,
  setCountry: PropTypes.func,
};

export default SelectCountry;
