import React from "react";
import SpinnerSmal from "../spinner/SpinnerSmal";
import PropTypes from "prop-types";

const SmallButton = ({ title, action, isLoading, className }) => {
  const onClick = () => {
    action();
  };
  return (
    <div>
      <button type="submit" onClick={onClick} className={className}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <SpinnerSmal />
          </div>
        ) : (
          title
        )}
      </button>
    </div>
  );
};
SmallButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
export default SmallButton;
