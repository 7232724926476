/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import logo from "../../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  dashboardIcon,
  diplomaIcon,
  Icon,
  usersIcon,
} from "../../assets/svg/Index";
import { customAnimation } from "../../utils/animations/animation";

function NavigationBar() {
  let navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [open, setOpen] = useState(-1);
  const [selectedPage, setSelectedPage] = useState("");
  const location = useLocation();
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const onSelectedPage = (page, url) => {
    setSelectedPage(page);
    navigate(url);
  };
  return (
    <div className="w-auto h-full bg-white">
      <Fragment>
        <div className="flex items-center justify-between">
          <img src={logo} alt="" className="items-center mb-10 " />
        </div>

        <p className="pb-2 pr-2 text-sm font-bold text-blue-900">
          قائمة الخدمات
        </p>
        <Accordion
          animate={customAnimation}
          open={false}
          icon={<></>}
          className="border-none rounded hover:bg-blue-gray-50 "
        >
          <AccordionHeader
            className={`border-none p-3 !text-blue-900 flex items-start justify-between ${
              location.pathname === "/dashboard" &&
              `bg-blue-800 !text-white !rounded `
            }`}
            onClick={() => {
              onSelectedPage("DASHBOARD", "/dashboard");
            }}
          >
            <div className="flex items-start w-2/3">
              {dashboardIcon}
              <p className="pr-2 text-sm font-bold">لوحة التحكّم </p>
            </div>
          </AccordionHeader>
        </Accordion>
        {localStorage.getItem("role") === "ROLE_ADMIN_ALECSO" ? (
          <></>
        ) : (
          <Accordion
            animate={customAnimation}
            open={open === 3}
            icon={<Icon id={3} open={open} />}
            className="border-none rounded "
          >
            <AccordionHeader
              className={`border-none pr-1 !text-blue-900 flex items-start justify-between`}
              onClick={() => {
                handleOpen(3);
              }}
            >
              <p className="pr-2 text-sm font-bold">إدارة الطلبة</p>
            </AccordionHeader>

            <AccordionBody className="border-none">
              <div
                onClick={() => {
                  onSelectedPage("STUDENTS_LIST", "/student");
                }}
                className={`cursor-pointer w-72 h-10 rounded font-bold ${
                  location.pathname === "/student"
                    ? `bg-blue-700 text-white`
                    : `hover:bg-gray-300 text-blue-900`
                }`}
              >
                <h1 className="p-2"> قائمة الطلبة</h1>
              </div>

              {localStorage.getItem("role") === "ROLE_AGENT_UNI" ? (
                <div
                  onClick={() => {
                    onSelectedPage("STUDENTS_ADD", "/add_student");
                  }}
                  className={`cursor-pointer w-72 h-10 rounded font-bold ${
                    location.pathname === "/add_student"
                      ? `bg-blue-700 text-white`
                      : `hover:bg-gray-300 text-blue-900`
                  }`}
                >
                  <h1 className="p-2"> إضافة طلبة</h1>
                </div>
              ) : (
                <></>
              )}
            </AccordionBody>
          </Accordion>
        )}
        {localStorage.getItem("role") === "ROLE_ADMIN_ALECSO" ? (
          <></>
        ) : (
          <Accordion
            animate={customAnimation}
            open={open === 4}
            icon={<Icon id={4} open={open} />}
            className="border-none rounded "
          >
            <AccordionHeader
              className={`border-none pr-1 !text-blue-900 flex items-start justify-between`}
              onClick={() => {
                handleOpen(4);
              }}
            >
              <div className="flex items-start w-2/3">
                {diplomaIcon}

                <p className="text-sm font-bold ">إدارة الشهادات </p>
              </div>
            </AccordionHeader>
            <AccordionBody className="border-none">
              <div
                onClick={() => {
                  setSelectedPage("DIPLOMAS_LIST");
                  navigate("/diplomasList");
                }}
                className={`cursor-pointer w-72 h-10 rounded font-bold ${
                  location.pathname === "/diplomasList"
                    ? `bg-blue-700 text-white`
                    : `hover:bg-gray-300 text-blue-900`
                }`}
              >
                <h1 className="p-2"> قائمة الشهادات </h1>
              </div>

              {localStorage.getItem("role") === "ROLE_RESP_UNI" && (
                <div
                  onClick={() => {
                    setSelectedPage("DIPLOMAS_NOT_COMFIRM");
                    navigate("/listsAgent");
                  }}
                  className={`cursor-pointer w-72 h-10 rounded font-bold ${
                    selectedPage === "DIPLOMAS_NOT_COMFIRM" ||
                    location.pathname === "/listsAgent"
                      ? `bg-blue-700 text-white`
                      : `hover:bg-gray-300 text-blue-900`
                  }`}
                >
                  <h1 className="p-2"> شهادات بانتظار التأكيد </h1>
                </div>
              )}
              {localStorage.getItem("role") === "ROLE_AGENT_UNI" && (
                <div
                  onClick={() => {
                    setSelectedPage("DIPLOMAS_ADD");
                    navigate("/add_diplomas");
                  }}
                  className={`cursor-pointer w-72 h-10 rounded font-bold ${
                    location.pathname === "/add_diplomas"
                      ? `bg-blue-700 text-white`
                      : `hover:bg-gray-300 text-blue-900`
                  }`}
                >
                  <h1 className="p-2"> إضافة شهادة </h1>
                </div>
              )}
              {localStorage.getItem("role") === "ROLE_AGENT_UNI" && (
                <div
                  onClick={() => {
                    setSelectedPage("DIPLOMAS_NOT_COMFIRM");
                    navigate("/diplomas_not_confirm");
                  }}
                  className={`cursor-pointer w-72 h-10 rounded font-bold ${
                    selectedPage === "DIPLOMAS_NOT_COMFIRM" ||
                    location.pathname === "/listsAgent"
                      ? `bg-blue-700 text-white`
                      : `hover:bg-gray-300 text-blue-900`
                  }`}
                >
                  <h1 className="p-2"> شهادات بانتظار التأكيد </h1>
                </div>
              )}
              {localStorage.getItem("role") !== "ROLE_ADMIN_ALECSO" && (
                <div
                  onClick={() => {
                    setSelectedPage("DIPLOMAS_REJECTED");
                    navigate("/diplomasRejected");
                  }}
                  className={`cursor-pointer w-72 h-10 rounded font-bold ${
                    selectedPage === "DIPLOMAS_REJECTED" ||
                    location.pathname === "/diplomasRejected"
                      ? `bg-blue-700 text-white`
                      : `hover:bg-gray-300 text-blue-900`
                  }`}
                >
                  <h1 className="p-2"> قائمة الشهادات المرفوضة </h1>
                </div>
              )}
            </AccordionBody>
          </Accordion>
        )}

        {role === "ROLE_RESP_UNI" || role === "ROLE_ADMIN_ALECSO" ? (
          <Accordion
            animate={customAnimation}
            open={open === 5}
            icon={<Icon id={5} open={open} />}
            className="border-none rounded "
          >
            <AccordionHeader
              className="border-none !text-blue-900 flex items-start justify-between"
              onClick={() => handleOpen(5)}
            >
              <div className="flex items-start w-full">
                {usersIcon}
                <p className="pr-2 text-sm font-bold"> إدارة المستخدمين </p>
              </div>
            </AccordionHeader>
            <AccordionBody className="border-none">
              <div
                onClick={() => {
                  setSelectedPage("USERS_LIST");
                  navigate("/usersList");
                }}
                className={`cursor-pointer w-72 h-10 rounded font-bold ${
                  location.pathname === "/usersList" ||
                  selectedPage === "USERS_LIST"
                    ? `bg-blue-700 text-white`
                    : `hover:bg-gray-300 text-blue-900`
                }`}
              >
                <h1 className="p-2"> قائمة المستخدمين</h1>
              </div>
              <div
                onClick={() => {
                  setSelectedPage("USERS_ADD");
                  navigate("/add_user");
                }}
                className={`cursor-pointer w-72 h-10 rounded font-bold ${
                  location.pathname === "/add_user" ||
                  selectedPage === "USERS_ADD"
                    ? `bg-blue-700 text-white`
                    : `hover:bg-gray-300 text-blue-900`
                }`}
              >
                <h1 className="p-2"> إضافة مستخدم </h1>
              </div>
            </AccordionBody>
          </Accordion>
        ) : (
          <></>
        )}

        <div className="w-2/3 border border-blue-900" />
        <Accordion
          icon={<></>}
          open={open === 6}
          className="border-none rounded hover:bg-blue-gray-50"
        >
          <AccordionHeader
            className={`border-none p-3 mt-4 !text-blue-900 flex items-start justify-between ${
              location.pathname === "/settings" &&
              `bg-blue-800 !text-white !rounded `
            }`}
            onClick={() => {
              setSelectedPage("SETTINGS");
              handleOpen(0);
              navigate("/settings");
            }}
          >
            <p className="pr-2 text-sm font-bold"> إعدادات</p>
          </AccordionHeader>
        </Accordion>
        <Accordion
          icon={<></>}
          open={open === 7}
          className="border-none rounded hover:bg-blue-gray-50"
        >
          <AccordionHeader
            className={`border-none p-3 !text-blue-900 flex items-start justify-between ${
              selectedPage === "EXIT" && `bg-blue-800 !text-white !rounded `
            }`}
            onClick={() => {
              setSelectedPage("EXIT");
              handleOpen(0);

              navigate("/login");
              localStorage.removeItem("role");
              localStorage.removeItem("token");
            }}
          >
            <p className="pr-2 text-sm font-bold"> خروج</p>
          </AccordionHeader>
        </Accordion>
      </Fragment>
    </div>
  );
}

export default NavigationBar;
