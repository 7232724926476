import React, { useState } from "react";
import AddStudentsTable from "./AddStudentsTable";
import AddStudentsUploader from "./AddStudentsUploader";
import ErrorBoundary from "../../utils/ErrorBoundary";

import MainButton from "../../components/button/MainButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD_FROM_CSV } from "../../store/features/studentsSlice/studentsSlice";
import DeleteStdentModal from "../../components/modal/Student/DeleteStudentModal";
import AddStudentBeforeErrorModal from "../../components/modal/Student/AddStudentBeforeErrorModal";
import AddStudentAfterErrorModal from "../../components/modal/Student/AddStudentAfterErrorModal";
function AddStudentsPage() {
  const students = useSelector((state) => state.students);
  const [error, setError] = useState([]);
  const [key, setKey] = useState(0);
  const [errorAdd, setErrorAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [studentToDelete, setStudentToDelete] = useState({});

  const [errorTable] = useState([]);
  const [updateTable] = useState([]);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  /**
   * @function deleteStudent
   * @description this function to open Model and  delete Student
   *
   * @return
   */
  const deleteStudent = () => {
    const studentsTable = [...students.studentsToAdd];
    studentsTable.splice(key, 1);
    dispatch(ADD_FROM_CSV({ studentsToAdd: studentsTable }));
    setOpenModal(!openModal);
  };

  return (
    <div className="flex flex-col justify-end mt-10 px-[3%] ">
      <h1 className="mb-2 text-2xl font-bold text-indigo-900">إضافة الطلبة</h1>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white h-fit">
        <ErrorBoundary>
          <div className="flex w-full pt-8 pr-10">
            <div
              onClick={() => {
                setSelectedMenu(1);
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 1 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              إضافة جماعية
            </div>
            <div
              onClick={() => {
                setSelectedMenu(2);
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 2 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              إضافة فردية
            </div>
          </div>
          {selectedMenu === 1 ? (
            <AddStudentsUploader setError={setError} setOpen={setOpen} />
          ) : (
            <div className="flex flex-col w-full p-4 rounded bg-blue-50 h-fit">
              <p>اضغط على الزر أسفله لتعمير استمارة إضافة طالب.</p>
              <div className="w-40 py-4">
                <MainButton
                  title=" إضافة طالب"
                  action={() => {
                    navigate("/add_one_student");
                  }}
                />
              </div>
            </div>
          )}
        </ErrorBoundary>
        {selectedMenu === 1 && (
          <ErrorBoundary>
            <div className="w-full ">
              <AddStudentsTable
                errorAdd={errorAdd}
                setErrorAdd={setErrorAdd}
                updateTable={updateTable}
                errorTable={errorTable}
                setOpenModal={setOpenModal}
                openModal={setOpenModal}
                setKey={setKey}
                setStudentToDelete={setStudentToDelete}
              />
            </div>
          </ErrorBoundary>
        )}
      </div>
      {open ? (
        <AddStudentBeforeErrorModal error={error} setOpen={setOpen} />
      ) : null}
      {openModal ? (
        <DeleteStdentModal
          setOpenModal={setOpenModal}
          openModal={openModal}
          deleteStudent={deleteStudent}
          studentToDelete={studentToDelete}
        />
      ) : null}
      {errorAdd ? (
        <AddStudentAfterErrorModal
          setErrorAdd={setErrorAdd}
          errorTable={errorTable}
          updateTable={updateTable}
        />
      ) : null}
    </div>
  );
}

export default AddStudentsPage;
