import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  cameraIcon,
  codeIcon,
  qrCodeIcond,
  searchIcon,
} from "../../assets/svg/Index";
import Input from "../../components/input/Input";
import Spinner from "../../components/spinner/Spinner";
import { useVerifyDiplomaMutation } from "../../store/service/diplomasService";

import ErrorBoundary from "../../utils/ErrorBoundary";
import QRScan from "./qr-reder.component";

function ThirdPartyPage() {
  let [verify] = useVerifyDiplomaMutation();
  const [spinnerActive, setSpinnerActive] = useState(false);

  const [result, setResult] = useState("");
  const [idDiploma, setIdDiploma] = useState("");
  const [hashIpfs, setHashIpfs] = useState("");
  const [diploma, setDiploma] = useState({});
  const [showCamera, setShowCamera] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const searchDiploma = () => {
    setSpinnerActive(true);
    let data = {
      diplomaId: idDiploma,
    };
    verify(data).then((res) => {
      setDiploma(res.data.data);
      if (res.data.data) {
        setHashIpfs(res.data.data.hashIpfs);
        setSpinnerActive(false);
      } else {
        setHashIpfs(null);
        setSpinnerActive(false);
      }
    });
    setHashIpfs(null);
    setResult("");
    setIdDiploma("");
  };
  useEffect(() => {
    let data = {
      idDiploma: result,
    };
    verify(data).then((res) => {
      if (res.data.data) {
        setHashIpfs(res.data.data.hashIpfs);
        setSpinnerActive(false);
        setDiploma(res.data.data);
      } else {
        setHashIpfs(null);
        setSpinnerActive(false);
      }
    });
  }, [result, diploma]);
  return (
    <div className="flex flex-col justify-start w-full h-full p-12 overflow-hidden bg-blue-50">
      <h1 className="text-2xl font-bold tex-blue-900">فضاء المؤسسة</h1>
      <div className="flex flex-col md:flex md:flex-row  items-start justify-between w-[80%]">
        <div className="flex flex-col justify-start w-full h-full mt-8">
          <p className="text-sm text-gray-700">التحقق من صحّة شهادة :</p>
          <div className="flex flex-col items-start justify-start w-full mt-8 md:flex-row">
            <div className="mb-4 md:mb-0 rounded-xl bg-white w-full md:w-[400px] p-4 h-fit flex items-start justify-end ml-8">
              <div className="mx-2 ml-4 mr-10 bg-blue-300 w-30 h-30">
                {codeIcon}
              </div>
              <div className="flex flex-col h-52">
                <p className="text-gray-500">كتابة معرّف الشهادة</p>
                <p>
                  يمكنك كتابة معرّف الشهادة الموجود فوق رسم الـ QR-CODE وسيقوم
                  النظام بالبحث عليه.
                </p>
                <div className="flex flex-col items-end justify-end mt-2">
                  <div className="w-full">
                    <Input
                      placeholder="معرّف الشهادة"
                      value={idDiploma}
                      inputChange={setIdDiploma}
                    />
                  </div>
                  <button
                    onClick={searchDiploma}
                    className="flex items-center justify-center w-full h-8 space-x-2 text-white bg-blue-300 rounded-sm "
                  >
                    {searchIcon}
                    <p> بحث</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="rounded-xl bg-white w-full md:w-[400px] p-4 h-64 flex items-start justify-start">
              <div className="mx-2 ml-4 mr-10 bg-deep-purple-300 w-30 h-30">
                {qrCodeIcond}
              </div>
              <ErrorBoundary>
                <div className="flex flex-col ">
                  {showCamera ? (
                    <QRScan
                      result={result}
                      setResult={setResult}
                      setShowCamera={setShowCamera}
                    />
                  ) : (
                    <div>
                      <p className="text-gray-500">
                        قراءة الQR-Code بواسطة الكاميرا
                      </p>
                      <p>
                        يمكنك فتح كاميرا الهاتف أو الحاسوب وتصوير رسم الـQR-CODE
                        الموجود بالشهادة.
                      </p>
                    </div>
                  )}

                  <div className="flex flex-col items-end justify-end mt-2">
                    <div className="w-full"></div>
                    {showCamera ? (
                      <button
                        onClick={() => {
                          setShowCamera(false);
                        }}
                        className="flex items-center justify-center w-full h-8 space-x-2 text-white rounded-sm bg-deep-purple-300 "
                      >
                        {cameraIcon}
                        <p> أغلق الكاميرا </p>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setShowCamera(true);
                        }}
                        className="flex items-center justify-center w-full h-8 space-x-2 text-white rounded-sm bg-deep-purple-300 "
                      >
                        {cameraIcon}
                        <p> افتح الكاميرا </p>
                      </button>
                    )}
                  </div>
                </div>
              </ErrorBoundary>
            </div>
          </div>

          <div className="flex flex-col items-start min-h-[500px] h-fit w-full  p-4 bg-white rounded-xl">
            <h2 className="mb-8 text-xl font-bold text-blue-900">
              نتائج البحث
            </h2>
            <div className="flex items-start justify-between w-full h-full">
              <div className="flex flex-col items-center justify-center w-full">
                <span className="w-full text-lg text-blue-900 ">
                  البيانات الوصفية
                </span>
                {hashIpfs && (
                  <div className="w-full pl-16 mb-4">
                    <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          السنة الجامعية :
                        </span>
                        <span className="mr-2 "> {diploma?.year}</span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700"> الاختصاص: </span>
                        <span className="mr-2 "> {diploma?.speciality}</span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          المعدّل / المجموع
                        </span>
                        <span className="mr-2 "> {diploma?.average} </span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">الملاحظة</span>
                        <span className="mr-2 "> {diploma?.honors} </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center w-full">
                <span className="text-lg text-blue-900">
                  صورة الشهادة على البلوكتشين
                </span>
                {spinnerActive && <Spinner />}

                {hashIpfs && (
                  <div className="flex items-center justify-center w-full max-w-[20px] rounded-t-xl thirdParty">
                    <Document
                      file={"https://saddakni.rnu.tn/ipfs/" + hashIpfs}
                      className="flex items-center justify-center"
                    >
                      <Page pageNumber={1} />
                    </Document>
                  </div>
                )}

                {hashIpfs === null && <span>هذه الشهادات غير موجودة </span>}
              </div>
              <span className="font-bold text-blue-900"> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdPartyPage;
