/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  GET_DIPLOMAS,
  SET_SELECTED_DIPL,
} from "../../store/features/diplomasSlice/diplomasSlice";

import {
  useGetDiplomasMutation,
  useUpdateDiplomasMutation,
} from "../../store/service/diplomasService";

import { Table } from "./Table";
import { useNavigate } from "react-router";

import Pagination from "../../components/pagination/Pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { convertNumbers2English, convertSlash2Dash } from "../../utils/convert";
import DiplomaModalWithCloseAndDeleteButtons from "../../components/modal/Diploma/DiplomaModalWithCloseAndDeleteButtons";
export default function DiplomasTable({ filtered, setFiltered, agentId }) {
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState([]);
  const [getDiplomas] = useGetDiplomasMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      getDiplomas(agentId).then((res) => {
        disptach(GET_DIPLOMAS({ diplomas: res.data.data }));
        if (search === "" && searchBy.length == 0) {
          setFiltered(res.data.data);
          setIsLoading(true);
        }
      });
    }
  }, [isloading]);

  const animatedComponents = makeAnimated();

  const diplomas = useSelector((state) => state.diploma.diplomas);

  const [updateDiplomas] = useUpdateDiplomasMutation();
  const disptach = useDispatch();
  const getDiploma = (item) => {
    disptach(SET_SELECTED_DIPL({ selectedDipl: item }));
  };
  const agent = useSelector((state) => state.students.agent);

  const updateDipl = (item) => {
    disptach(SET_SELECTED_DIPL({ selectedDipl: item }));
    updateDiplomas({
      decision: "rejected",
      diplomas: [item.item],
      createdBy: agent[0].createdBy,
    }).then((res) => {
      if (!res.error) {
        filter(item.item.student.nin);
        setOpen(false);
        toastSucces("تمت العملية بنجاح !");
      } else {
        toastErrorServer("هنالك خطأ من السرفر");
      }
    });
  };

  const filter = (nin) => {
    const filtered = diplomas.filter((item) => item.student.nin !== nin);
    disptach(GET_DIPLOMAS({ diplomas: filtered }));

    setFiltered(filtered);
  };
  const [open, setOpen] = useState(false);

  const [showUpdate, setShowUpdate] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    if (showUpdate === true) {
      setShowUpdate(false);
    }
  };
  const searchby = () => {
    const _filtered = diplomas.filter(
      (diploma) =>
        (searchBy.includes("nin") &&
          diploma.student.nin.includes(convertNumbers2English(search))) ||
        (searchBy.includes("type") && diploma.type.includes(search)) ||
        (searchBy.includes("signatureDate") &&
          diploma.signatureDate.includes(convertSlash2Dash(search))) ||
        (searchBy.includes("speciality") &&
          diploma.speciality.toLowerCase().includes(search.toLowerCase())) ||
        (searchBy.includes("honors") &&
          diploma.honors.toLowerCase().includes(search.toLowerCase()))
    );
    setFiltered(_filtered);
  };
  const filterage = () => {
    if (searchBy.length > 0) {
      for (let i = 0; i < searchBy.length; i++) {
        if (
          searchBy[i].includes("type") ||
          searchBy[i].includes("nin") ||
          searchBy[i].includes("speciality") ||
          searchBy[i].includes("honors") ||
          searchBy[i].includes("signatureDate")
        ) {
          searchby();
        }
      }
    } else if (setSearch != "") {
      const _filtered = diplomas.filter(
        (diploma) =>
          diploma?.student.firstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          diploma?.student.lastName
            ?.toLowerCase()
            .includes(search.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  useEffect(() => {
    filterage();
  }, [search, currentPage, searchBy]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSearchBy(array);
    });

    if (e?.length == []) {
      array.push("");
      setSearchBy([]);
    }
    setCurrentPage(1);
  };
  const handleChangeSelect = (e) => {
    setCurrentPage(1);
    setPerPage(e.target.value.toString());

    setIsLoading(!isLoading);
  };
  useEffect(() => {
    handleChangeSearch();
  }, [searchBy, isLoading]);

  const diploma = useSelector((state) => state.diploma.selectedDipl);

  const item = diploma.item;

  useEffect(() => {
    filterage();
  }, [search, currentPage]);
  const options = [
    { value: "nin", label: " المعرف الوطني" },
    { value: "type", label: " نوع الشهادة	" },
    { value: "speciality", label: " الاختصاص" },
    { value: "honors", label: "  الملاحظة" },
    { value: "signatureDate", label: " تاريخ الإمضاء	" },
  ];
  return (
    <>
      <div className="flex flex-col justify-start py-5 ">
        <div className="flex items-center justify-between w-full gap-3 px-2">
          <input
            type={"text"}
            className="w-1/4 h-10 transition ease-in-out delay-200 border rounded focus:outline-blue-600 "
            placeholder="ابحث  ...!"
            value={search}
            onChange={handleChange}
          />
          <Select
            options={options}
            className="w-2/3"
            closeMenuOnSelect={false}
            placeholder="اختار ...!"
            components={animatedComponents}
            onChange={handleChangeSearch}
            styles={{ height: "3rem", borderWidth: "2px" }}
            isMulti
          />
          {filtered.length > 5 ? (
            <select
              className="w-1/12 h-10 border rounded focus:outline-blue-600 focus:border-none"
              onChange={handleChangeSelect}
              type="number"
              value={perPage}
            >
              <option type="number" value={5}>
                5
              </option>
              <option type="number" value={10}>
                10
              </option>
              <option type="number" value={20}>
                20
              </option>
              <option type="number" defaultValue={30}>
                30
              </option>

              <option type="number" value={50}>
                50
              </option>
            </select>
          ) : null}
        </div>
        <div className="relative h-auto p-4 overflow-x-auto sm:rounded-lg">
          <Table
            filtered={filtered}
            getDiploma={getDiploma}
            handleOpen={handleOpen}
            updateDipl={updateDipl}
            isLoading={isLoading}
            setFiltered={setFiltered}
            currentPage={currentPage}
            perPage={perPage}
          />

          <div className="flex justify-start">
            {filtered.length > 5 ? (
              <Pagination
                perPage={perPage}
                total={filtered.length}
                paginate={paginate}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div>
        {open ? (
          <DiplomaModalWithCloseAndDeleteButtons
            diploma={diploma}
            item={item}
            handleOpen={handleOpen}
            showUpdate={showUpdate}
            setShowUpdate={setShowUpdate}
            setLoading={setLoading}
            updateDipl={updateDipl}
            setOpen={setOpen}
          />
        ) : null}
      </div>
    </>
  );
}
