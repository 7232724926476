/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  numberOfLogins: 0,
  lastLoginDate: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function

export default dashboardSlice.reducer;
