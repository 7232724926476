import React from "react";
import PropTypes from "prop-types";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["XLSX", "XLS"];
function FileInput({ onChange, OnClear, isUploaded }) {
  const children = (
    <div
      className={`transition ease-in-out delay-150  flex items-center justify-center w-full p-4 rounded ${
        isUploaded ? "bg-[#0cbe6b] text-white" : "bg-[#f3f3f3] text-black "
      }`}
    >
      {isUploaded ? (
        <div className="flex w-full items-center justify-between gap-2">
          <div className="flex items-center justify-center w-[99%] h-2 bg-gray-300 rounded-md">
            <div className="w-[99.5%] h-1 bg-blue-300 rounded-md"></div>
          </div>
          <p className="text-md font-bold text-[#25396f]">100%</p>
        </div>
      ) : (
        <p className="text-[#4f4f4f]">
          أفلت ملف Excel في هذا المستطيل أو اضغط هنا
        </p>
      )}
    </div>
  );
  return (
    <div className="relative">
      <FileUploader
        handleChange={onChange}
        onSelect={onChange}
        types={fileTypes}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        classes="w-full"
      />
      {isUploaded && (
        <button
          onClick={OnClear}
          className="absolute left-1 bottom-14 flex items-center justify-center w-5 h-5 text-white bg-[#dc3545] rounded-full"
        >
          <span className="mb-1 font-bold text-center">x</span>
        </button>
      )}
    </div>
  );
}
FileInput.propTypes = {
  onChange: PropTypes.func,
  OnClear: PropTypes.func,
  isUploaded: PropTypes.bool,
};
export default FileInput;
