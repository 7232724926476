/**
 * @function sortBy
 * @description this function sorty list by field
 *
 * @param {*} field
 * @param {*} status
 *
 * @returns list sorted
 */
const sortBy = (field, status) => {
  return (a, b) => {
    if (a[field] > b[field]) {
      return status ? -1 : 1;
    }
    if (b[field] > a[field]) {
      return status ? 1 : -1;
    }
    return 0;
  };
};

export default sortBy;
