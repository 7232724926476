/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agents: [],
};

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    GET_USERS: (state, action) => {
      state.agents = action.payload.agents;
    },
  },
});

// Action creators are generated for each case reducer function
export const { GET_USERS } = usersSlice.actions;

export default usersSlice.reducer;
