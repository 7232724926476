import { toastLogoutServer } from "../components/toast/Toast";

/**
 * @function logout
 * @description this function logout user when token expiry
 *
 * @returns
 */
const logout = () => {
  clearLocalStorage();
  toastLogoutServer("فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً ");
};

/**
 * @function clearLocalStorage
 * @description this function to clear the localStorage
 *
 * @returns
 */
const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
};
export { logout, clearLocalStorage };
