import jwtDecode from "jwt-decode";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { SIGN_IN } from "../../store/features/userSlice/userSlice";

import { useUpdateUserMutation } from "../../store/service/userService";
import { useChangePasswordMutation } from "../../store/service/usersService";
import {
  mailIcon,
  mobileIcon,
  passwordIcon,
  userIcon,
} from "../../assets/svg/Index";

function SettingPage() {
  let [changePassword] = useChangePasswordMutation();
  const [updateUser] = useUpdateUserMutation();

  const setIsNameValid = () => {
    return false;
  };
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);

  const [numero, setNumero] = useState("");

  const setIsNumeroalid = () => {
    return false;
  };
  const setIsOldPasswordValid = () => {
    return false;
  };
  const [oldPassword, setOldPassword] = useState("");

  const [password, setPassword] = useState("");

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [passwordRe, setPasswordRe] = useState("");

  const setIsPasswordReValid = () => {
    return false;
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const ForgotPassword = () => {
    const data = {
      oldPassword,
      password,
      token: localStorage.getItem("token"),
    };
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      toast.error("      فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً   ", {
        position: "top-left",
        theme: "colored",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      changePassword(data).then((res) => {
        if (res.error) {
          if (res.error.status === 401) {
            toast.error(" كلمة السر خاطئة   ");
          } else {
            toast.error("هنالك خطأ في السرفر ");
          }
        }
        if (res.data.message == "Password changed.") {
          setOldPassword("");
          setPassword("");
          setPasswordRe("");
          toast.info("تم تغيير كلمة السر", {
            position: "top-left",
            theme: "colored",
          });
        }
      });

      setTimeout(() => {
        setOldPassword("");
        setPassword("");
        setPasswordRe("");
      }, 2000);
    }
  };
  const user = useSelector((state) => state.user);

  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      toast.error("      فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً   ", {
        position: "top-left",
        theme: "colored",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setUsername(user?.username ? user?.username : username);
      setEmail(user?.email ? user?.email : email);
      setNumero(user?.numero ? user?.numero : numero);
    }
  }, [isLoading]);
  const dispatch = useDispatch();
  const _updateUser = () => {
    updateUser({
      username,
      email,
      numero,
    }).then((res) => {
      if (res.data) {
        dispatch(
          SIGN_IN({
            username: res.data.data.username,
            email: res.data.data.email,
            numero: res.data.data.numero,
          })
        );
        setIsLoading(true);
      } else {
        if (res.error.status === 401) {
          toast.error("هذا المستخدم موجود ");
        } else {
          toast.error("هنالك خطأ في السرفر ");
        }
      }
    });
  };
  return (
    <div className="flex flex-col w-full p-8">
      <h1 className="mb-2 text-2xl font-bold text-blue-900 ">إعدادات الحساب</h1>
      <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">الاسم الكامل</p>
          <Input
            icon={userIcon}
            type={"NO_CONTROL"}
            inputChange={setUsername}
            placeholder={user?.username}
            value={username}
            setValid={setIsNameValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900"> البريد الالكتروني</p>
          <Input
            icon={mailIcon}
            type={"EMAIL"}
            placeholder={user?.email}
            inputChange={setEmail}
            value={email}
            setValid={setEmailValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900"> الهاتف</p>
          <Input
            icon={mobileIcon}
            type={"NUMBER"}
            placeholder={user?.numero}
            inputChange={setNumero}
            value={numero}
            setValid={setIsNumeroalid}
          />
        </div>
        <div className="flex justify-end w-full">
          <div className="w-40 ">
            <MainButton
              title="حفظ التعديلات"
              action={() => {
                _updateUser();
              }}
              isLoading={isLoading}
              disabled={username === "" || email === "" || !emailValid}
            />
          </div>
        </div>
      </div>
      <h1 className="mt-6 mb-2 text-2xl font-bold text-blue-900 ">
        تغيير كلمة السّر
      </h1>
      <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">كلمة السّر الحالية </p>
          <Input
            icon={passwordIcon}
            type={"NO_CONTROL"}
            placeholder={"كلمة السّر الحالية"}
            password
            inputChange={setOldPassword}
            value={oldPassword}
            setValid={setIsOldPasswordValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900"> كلمة السّر الجديدة</p>
          <Input
            icon={passwordIcon}
            type="PASSWORD"
            placeholder={"كلمة السّر الجديدة"}
            password
            inputChange={setPassword}
            value={password}
            setValid={setIsPasswordValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900"> إعادة كلمة السّر</p>
          <Input
            icon={passwordIcon}
            type="PASSWORD"
            placeholder={"إعادة كلمة السّر"}
            password
            inputChange={setPasswordRe}
            value={passwordRe}
            setValid={setIsPasswordReValid}
          />
        </div>

        <div className="flex justify-end w-full">
          <div className="w-40 ">
            <MainButton
              title="حفظ التعديلات"
              action={ForgotPassword}
              disabled={
                !isPasswordValid ||
                (password === "" && passwordRe === "") ||
                password !== passwordRe
              }
              isLoad
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingPage;
