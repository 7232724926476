import React from "react";
import UsersListTable from "./UsersListTable";

const UsersList = () => {
  return (
    <div className="flex flex-col w-full p-8">
      <h1 className="mb-2 text-2xl font-bold text-blue-900">
        قائمة المستخدمين
      </h1>
      <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
        <UsersListTable />
      </div>
    </div>
  );
};

export default UsersList;
