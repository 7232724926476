const uploadJSON = (results) => {
  let tabObject = [];
  for (let i = 0; i < results.length; i++) {
    const res = {
      firstName: results[i]?.["firstName"],
      lastName: results[i]?.["lastName"],
      nin: results[i]?.["nin"]?.toString(),
      type: results[i]?.["type"],
      speciality: results[i]?.["speciality"],
      year: results[i]?.["year"],
      signatureDate: results[i]?.["signatureDate"],
      honors: results[i]?.["honors"],
      approvalDate: results[i]?.["approvalDate"],
      average: results[i]?.["average"],
      ligne: i,
    };
    tabObject.push(res);
  }

  return tabObject;
};

export { uploadJSON };
