import { utils, writeFile } from "xlsx";

function downloadFileDiploma() {
  let xlsData = [
    {
      firstName: "",
      lastName: "",
      nin: "",
      type: " ",
      speciality: " ",
      year: "  ",
      signatureDate: " ",
      honors: " ",
      approvalDate: "",
      average: " ",
    },
  ];
  const worksheet = utils.json_to_sheet(xlsData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, "data.xls");
}

export { downloadFileDiploma };
