/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input/Input";
import { useUpdateDiplomaMutation } from "../../store/service/diplomasService";
import { SET_SELECTED_DIPL } from "../../store/features/diplomasSlice/diplomasSlice";
import { useDispatch } from "react-redux";

const Update = ({
  diploma,
  handleOpen,
  setShowUpdate,
  showUpdate,
  setLoading,
}) => {
  const setIsUniversityNameValid = () => {
    return true;
  };
  const [type, setType] = useState("");
  const [firstName] = useState(diploma?.item?.student?.firstName);
  const [lastName] = useState(diploma?.item?.student?.lastName);
  const [dateOfBirth] = useState(diploma?.item?.student?.dateOfBirth);
  const [countryOfBirth] = useState(diploma?.item?.student?.countryOfBirth);
  const [honors, setHonors] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [year, setYear] = useState("");
  const [average, setAverage] = useState(0);
  const [signatureDate, setSignatureDate] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [idDiploma, setIdDiploma] = useState(diploma?.item._id);
  const [filename] = useState(diploma?.item.filename);
  const [hashIpfs] = useState(diploma?.item?.hashIpfs);
  const [nin] = useState(diploma?.item?.student.nin);
  useEffect(() => {
    setHonors(diploma?.item.honors ? diploma?.item.honors : "");
    setType(diploma?.item.type ? diploma?.item.type : "");

    setSpeciality(diploma?.item.speciality ? diploma?.item.speciality : "");
    setYear(diploma?.item.year ? diploma?.item.year : "");
    setAverage(diploma?.item.average ? diploma?.item.average : "");
    setSignatureDate(
      diploma?.item.signatureDate ? diploma?.item.signatureDate : ""
    );
    setApprovalDate(
      diploma?.item.approvalDate ? diploma?.item.approvalDate : ""
    );
    setIdDiploma(diploma?.item._id ? diploma?.item._id : "");
  }, []);
  const [updateDiploma] = useUpdateDiplomaMutation();

  const dispatch = useDispatch();
  const update = () => {
    updateDiploma({
      diploma: {
        type: type,
        idDiploma: idDiploma,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        countryOfBirth: countryOfBirth,
        honors: honors,
        speciality: speciality,
        average: average,
        year: year,
        signatureDate: signatureDate,
        approvalDate: approvalDate,
        filename: filename,
        hashIpfs: hashIpfs,
      },
    });

    dispatch(
      SET_SELECTED_DIPL({
        selectedDipl: {
          item: {
            student: {
              firstName: firstName,
              lastName: lastName,
              countryOfBirth: countryOfBirth,
              dateOfBirth: dateOfBirth,
              nin: nin,
            },
            type: type,
            honors: honors,
            _id: idDiploma,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dateOfBirth,
            countryOfBirth: countryOfBirth,
            speciality: speciality,
            average: average,
            year: year,
            signatureDate: signatureDate,
            approvalDate: approvalDate,
            filename: filename,
            hashIpfs: hashIpfs,
          },
        },
      })
    );
    setLoading(true);
    setShowUpdate(!showUpdate);
  };
  const setApprovalDateValid = () => {
    return true;
  };
  const setSignatureDateValid = () => {
    return true;
  };

  return (
    <div className="relative w-auto mx-auto ">
      <div className="relative flex flex-col w-full bg-white border-0 rounded-lg outline-none focus:outline-none">
        <div className="bg-white rounded">
          <div className="flex items-center justify-center w-full pt-8 h-fit"></div>
          <div className="flex items-center justify-center w-full p-2 h-fit">
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">* الاختصاص</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={speciality}
                  inputChange={setSpeciality}
                  value={speciality}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">نوع الشهادة</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={type}
                  inputChange={setType}
                  value={type}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900"> الملاحظة </p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={honors}
                  inputChange={setHonors}
                  value={honors}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">السنة الجامعية</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={year}
                  inputChange={setYear}
                  value={year}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full p-2 h-fit">
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">المعدّل</p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={average.toString()}
                  inputChange={setAverage}
                  value={average}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">تاريخ التوقيع</p>
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={approvalDate}
                  inputChange={setApprovalDate}
                  value={approvalDate}
                  setValid={setApprovalDateValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-1/3 mx-2">
              <div className="flex flex-col items-start justify-center w-full">
                <p className="text-xl text-blue-900">تاريخ المصادقة </p>
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={signatureDate}
                  inputChange={setSignatureDate}
                  value={signatureDate}
                  setValid={setSignatureDateValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-4 border-t border-solid rounded-b border-slate-200">
          <button
            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-black uppercase transition-all duration-150 ease-linear bg-gray-400 rounded shadow outline-none active:bg-gray-900 hover:shadow-lg focus:outline-none"
            type="button"
            onClick={() => handleOpen()}
          >
            غلق النافذة
          </button>
          <button
            className="px-10 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-800 rounded shadow outline-none active:bg-yellow-900 hover:shadow-lg focus:outline-none"
            type="button"
            onClick={() => {
              update();
            }}
          >
            تأكيد
          </button>
        </div>
      </div>
    </div>
  );
};
Update.propTypes = {
  diploma: PropTypes.object,
  handleOpen: PropTypes.func,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setLoading: PropTypes.func,
};
export default Update;
