import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";
const AddStudentAfterErrorModal = ({
  setErrorAdd,
  errorTable,
  updateTable,
}) => {
  return (
    <ModalLayout>
      <div className="relative  my-6 mx-auto w-[500px]">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-3 bg-white border-b border-solid rounded-t border-slate-200">
            <h3 className="text-2xl font-semibold text-blue-900">
              {errorTable?.length === 0 ? " العملية ناجحة" : "التنبيه"}
            </h3>
          </div>
          {/*body*/}
          <div className="relative p-4 flex-auto max-h-[60vh] overflow-y-auto ">
            <span className="pb-3 text-2xl font-semibold"></span>
            <div className="grid justify-start pt-4 text-right">
              {errorTable[0][0]?.length === 0 ? null : (
                <span>لم يقع إضافة هؤلاء الطلبة نظراً لوجودهم</span>
              )}
              {errorTable &&
                errorTable[0][0]?.map((e) => (
                  <div key={e} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-700">
                      المعرّف : {e.nin}
                    </span>
                  </div>
                ))}
              {updateTable[0][0]?.length === 0 ? null : (
                <span>
                  تمت إضافة بيانات الطلبة بنجاح مع العلم أن هناك طلبة سبق أن وقع
                  تسجيلهم بجامعات أخرى وهم :
                </span>
              )}
              {updateTable &&
                updateTable[0][0]?.map((e) => (
                  <div key={e} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-700">
                      المعرّف : {e.nin}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          {/*footer*/}

          <div className="flex items-center justify-center p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-500 rounded shadow outline-none active:bg-gray-900 hover:shadow-lg focus:outline-none"
              onClick={() => setErrorAdd(false)}
            >
              غلق النافذة
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
AddStudentAfterErrorModal.propTypes = {
  setErrorAdd: PropTypes.func,
  errorTable: PropTypes.array,
  updateTable: PropTypes.array,
};
export default AddStudentAfterErrorModal;
