import React from "react";
import PropTypes from "prop-types";
const StudentInfo = ({ student }) => {
  const data = student;
  return (
    <div className="flex flex-col items-start justify-start bg-gray-200 w-[450px] h-[800px] rounded-xl ml-8 ">
      {data.photo ? (
        <img src={data.photo} className="w-full h-80 rounded-t-xl" />
      ) : (
        <div className="flex items-center justify-center w-full max-w-sm p-6 mx-auto   rounded-md  cursor-pointer animate-pulse h-80">
          <div className="w-40 h-40 bg-gray-700 rounded-full"></div>
        </div>
      )}

      <span className="p-4  mr-12 text-xl font-bold text-blue-900">
        {data.firstName} {data.lastName}
      </span>
      <p className="mr-4 text-gray-600 "></p>
      <div className="flex w-full py-2 mt-2 space-x-2 ">
        <div className="flex items-start w-full pb-2 pl-1 mr-12 text-sm border-b-gray-400">
          <span className="mr-4 text-gray-700"> المعرف الوطني : </span>
          <span className="mr-2 "> {data.nin}</span>
        </div>
      </div>
      <div className="flex w-full py-2 pl-1 space-x-2 text-sm">
        <div className="flex items-start w-full pb-2 mr-12  border-b-gray-400">
          <span className="mr-4 text-gray-700"> تاريخ الميلاد :</span>
          <span className="mr-2 "> {data.dateOfBirth}</span>
        </div>
      </div>
      <div className="flex w-full py-2 space-x-2 text-sm">
        <div className="flex items-start w-full pb-2 pl-1 mr-12 border-b-gray-400">
          <span className="mr-4 text-gray-700"> مكان الولادة :</span>
          <span className="mr-2 "> {data.countryOfBirth}</span>
        </div>
      </div>
      <div className="flex w-full py-2 space-x-2 text-sm">
        <div className="flex items-start w-full pb-2 pl-1 mr-12  border-b-gray-400">
          <span className="mr-4 text-gray-700"> البريد الاكتروني </span>
          <span className="mr-2 "> {data.email}</span>
        </div>
      </div>
      <div className="flex w-full space-x-2 text-sm">
        <div className="flex items-start w-full pb-2 pl-1 mr-12 ">
          <span className="mr-4 text-gray-700 "> الهاتف :</span>
          <span className="mr-2 "> {data.phone}</span>
        </div>
      </div>
    </div>
  );
};
StudentInfo.propTypes = {
  student: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default StudentInfo;
