import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { nextIcon, pagesIcon, prevIcon } from "../../assets/svg/Index";

const Pagination = ({ perPage, total, paginate, pageOne }) => {
  const perpage = parseInt(perPage);
  const pagesNumbers = [];
  for (let i = 1; i <= Math.ceil(total / perpage); i++) {
    pagesNumbers.push(i);
  }
  const [page, setPage] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const pages = pagesNumbers.length;
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  useEffect(() => {
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    setPage(1);
  }, [pageOne]);
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if (currentPage == pagesNumbers[0]) {
      return null;
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleClick = (event) => {
    setcurrentPage(event);
  };

  return (
    <nav className="flex items-center justify-between w-full p-2">
      <ul className="flex items-center justify-between w-70">
        {page === 1 ? (
          <></>
        ) : (
          <li
            className={
              "  p-2 w-10 cursor-pointer  text-center bg-white hover:bg-blue-900 text-black "
            }
            onClick={() => {
              setPage(page == 1 ? 1 : page - 1);
              paginate(page == 1 ? 1 : page - 1);
              handlePrevbtn();
            }}
          >
            <span>{nextIcon}</span>
          </li>
        )}

        {pagesNumbers.map((number) => {
          if (pagesNumbers.length > 5) {
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  onClick={() => {
                    setPage(number);
                    paginate(number);
                    setcurrentPage(number);
                    handleClick(number);
                  }}
                  className={`  p-2 w-10 cursor-pointer text-center  ${
                    number === page
                      ? "shadow-md bg-blue-900  hover:bg-blue-900   text-white "
                      : " hover:bg-gray-500 text-black"
                  }`}
                >
                  {number}
                </li>
              );
            }
          } else {
            return (
              <li
                key={number}
                onClick={() => {
                  setPage(number);
                  paginate(number);
                  setcurrentPage(number);
                  handleClick(number);
                }}
                className={`  p-2 w-10 cursor-pointer text-center  ${
                  number === page
                    ? "shadow-md bg-blue-900  hover:bg-blue-900   text-white "
                    : " hover:bg-gray-500 text-black"
                }`}
              >
                {number}
              </li>
            );
          }
        })}
        {page === pagesNumbers.length ? (
          <></>
        ) : (
          <li
            className={
              " flex p-2 w-10 cursor-pointer  text-center bg-white hover:bg-blue-900 text-black "
            }
            onClick={() => {
              paginate(
                page !== pagesNumbers.length
                  ? (page) => page + 1
                  : pagesNumbers.length
              );
              setPage(
                page !== pagesNumbers.length
                  ? (page) => page + 1
                  : pagesNumbers.length
              );
              handleNextbtn();
            }}
          >
            <span>{prevIcon}</span>
          </li>
        )}
      </ul>
      <span className="flex justify-between gap-2 font-semibold w-34">
        <span className="text-blue-900 "> {pages} </span>
        <span className="flex text-blue-900">عدد الصفحات {pagesIcon} </span>
      </span>
    </nav>
  );
};
Pagination.propTypes = {
  perPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  paginate: PropTypes.func,
  pageOne: PropTypes.bool,
};

export default Pagination;
