/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GET_DIPLOMAS } from "../../store/features/diplomasSlice/diplomasSlice";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { useNavigate } from "react-router-dom";

const Table = ({
  filtered,
  getDiploma,
  handleOpen,
  setFiltered,
  currentPage,
  perPage,
}) => {
  const dispatch = useDispatch();
  const [nameClicked, setNameClicked] = useState(false);
  const [typeClicked, setTypeClicked] = useState(false);
  const [specialityClicked, setSpecialityClicked] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);
  const navigate = useNavigate();
  const sortBy = (field, status) => {
    return (a, b) => {
      if (a[field] > b[field]) {
        return status ? -1 : 1;
      }
      if (b[field] > a[field]) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };

  const sortByName = (field, status) => {
    return (a, b) => {
      if (a.student[field] > b.student[field]) {
        return status ? -1 : 1;
      }
      if (b.student[field] > a.student[field]) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const onNameClick = () => {
    setNameClicked(!nameClicked);
    const sorted = [...filtered].sort(sortByName("firstName", nameClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onSpecialityClick = () => {
    setSpecialityClicked(!specialityClicked);
    const sorted = [...filtered].sort(sortBy("speciality", specialityClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onDateClick = () => {
    setDateClicked(!dateClicked);
    if (dateClicked) {
      const sorted = [...filtered].sort((a, b) => {
        return moment(b.signatureDate).isBefore(moment(a.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    } else {
      const sorted = [...filtered].sort((a, b) => {
        return moment(a.signatureDate).isBefore(moment(b.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    }
  };

  const onTypeClick = () => {
    setTypeClicked(!typeClicked);
    const sorted = [...filtered].sort(sortBy("type", typeClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  return (
    <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400 ">
      <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onNameClick}
            >
              <span> الطالب(ة) </span>
              {nameClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3">
            المعرف الوطني
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onTypeClick}
            >
              <span> نوع الشهادة </span>
              {typeClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-1 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onSpecialityClick}
            >
              <span> الاختصاص</span>
              {specialityClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3">
            الملاحظة
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onDateClick}
            >
              <span> تاريخ الإمضاء </span>
              {dateClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh">
        {filtered
          ?.slice(currentPage * perPage - perPage, currentPage * perPage)
          .map((item, key) => (
            <tr
              key={key}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-[#f2f2f2]` : `bg-white`
              }`}
            >
              <td className="px-2 py-2">
                {item.student.firstName} {item.student.lastName}
              </td>
              <td className="px-2 py-2">{item?.student.nin}</td>
              <td className="px-2 py-2">{item?.type}</td>
              <td className="px-2 py-2">{item?.speciality}</td>
              <td className="px-2 py-2">{item?.honors}</td>
              <td className="px-2 py-2">
                {item?.signatureDate === "Invalid date"
                  ? null
                  : item?.signatureDate}
              </td>
              <td className="flex items-center justify-center px-2 py-2">
                <button
                  className="px-2 py-1 mx-1 text-xs font-bold text-white bg-[#198754] rounded w-fi h-fit"
                  onClick={() => {
                    getDiploma({ item });
                    handleOpen();
                  }}
                >
                  الشهادة
                </button>
                <button
                  className="px-2 py-1 mx-1 text-xs font-bold text-white bg-[#0dcaf0] rounded w-fi h-fit"
                  onClick={() => {
                    navigate(`/ficheStudent/${item.student?.nin}`);
                  }}
                >
                  حساب الطالب
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  filtered: PropTypes.array,
  getDiploma: PropTypes.func,
  handleOpen: PropTypes.func,
};
export default Table;
