import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";
const AddStudentBeforeErrorModal = ({ error, setOpen }) => {
  return (
    <ModalLayout>
      <div className="relative w-auto mx-auto my-6 max-w-auto ">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-3 bg-red-900 border-b border-solid rounded-t border-slate-200">
            <h3 className="text-2xl font-semibold text-white">خطأ</h3>
          </div>
          {/*body*/}
          <div className="relative p-4 flex-auto max-h-[60vh] overflow-y-auto ">
            <span className="pb-3 text-2xl font-semibold">
              لم يقع احتساب الأسطر التالية لعدم صحتها
            </span>
            <div className="grid justify-start pt-4 text-right">
              {error &&
                error?.map((e) => (
                  <div key={e} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-700">
                      السطر : {e.ligne}
                    </span>
                    <span className="font-semibold">
                      الخانات : {e.colonnes}
                    </span>
                  </div>
                ))}
            </div>
            <div className="flex justify-center pt-4">
              <span className="text-2xl"> يرجى التحقق من الأسطر</span>
            </div>
          </div>
          {/*footer*/}

          <div className="flex items-center justify-center p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-900 rounded shadow outline-none active:bg-gray-900 hover:shadow-lg focus:outline-none"
              onClick={() => setOpen(false)}
            >
              غلق النافذة
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
AddStudentBeforeErrorModal.propTypes = {
  setOpen: PropTypes.func,
  error: PropTypes.array,
};
export default AddStudentBeforeErrorModal;
