import React, { useEffect, useState } from "react";
import logo from "../../assets/certified_logo.png";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { useNavigate, useParams } from "react-router-dom";
import jwt from "jwt-decode";
import axios from "axios";
import {
  useRegisterAgentUniMutation,
  useRegisterRespUniMutation,
} from "../../store/service/usersService";

import {
  homeIcon,
  mailIcon,
  passwordIcon,
  userIcon,
} from "../../assets/svg/Index";
import { toastSucces } from "../../components/toast/Toast";

function RegisterPage() {
  let { token } = useParams();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [rePassword, setRePassword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const setIsRePasswordValid = () => {
    return false;
  };
  const [isLoading, setIsLoading] = useState(false);
  const [register] = useRegisterRespUniMutation();
  const [registerAgent] = useRegisterAgentUniMutation();

  const registerResUniversitaire = () => {
    if (jwt(token).userRole !== "Agent") {
      setIsLoading(true);
      let data = {
        email,
        password,
      };
      register(data).then((res) => {
        if (res.data) {
          setIsLoading(false);
          navigate("/login");
          toastSucces("تمت العملية بنجاح");
        } else {
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(true);
      let data = {
        email,
        password,
      };
      registerAgent(data).then((res) => {
        if (res.data) {
          setIsLoading(false);
          navigate("/login");
          toastSucces("تمت العملية بنجاح ");
        } else {
          setIsLoading(false);
        }
      });
    }
    localStorage.clear();
  };

  const getuser = async () => {
    let res = await axios({
      method: "GET",
      url: "https://saddakni.rnu.tn/api/user/getUserRegister",
      headers: {
        "x-access-token": `${token}`,
      },
    });
    if (res.data.data.firstLogin === true) {
      setRegister(true);
    }
  };
  const [resgister, setRegister] = useState(false);
  useEffect(() => {
    getuser();
  }, []);
  return (
    <>
      {resgister ? (
        <div className="flex flex-col items-start justify-start space-y-8 p-14">
          <img src={logo} alt="" className="mb-2" />
          <h1 className="text-5xl font-bold text-blue-800">مستخدم جديد</h1>
          <div>
            <p>هذا الرابط صالح لـ48 ساعة من تاريخ إرساله.</p>
          </div>
          <Input
            icon={homeIcon}
            placeholder={jwt(token).userToDecode?.universityName}
            disabled
            type={"DISABLED"}
          />
          <Input
            icon={userIcon}
            placeholder={jwt(token).userToDecode?.username}
            disabled
            type={"DISABLED"}
          />
          <Input
            icon={mailIcon}
            inputChange={setEmail}
            value={jwt(token).userToDecode?.email}
            disabled
            type={"DISABLED"}
          />
          <Input
            icon={passwordIcon}
            placeholder="كلمة العبور"
            password
            inputChange={setPassword}
            value={password}
            type="PASSWORD"
            setValid={setIsPasswordValid}
          />
          <Input
            icon={passwordIcon}
            placeholder="تأكيد كلمة العبور "
            password
            inputChange={setRePassword}
            value={rePassword}
            type="PASSWORD"
            setValid={setIsRePasswordValid}
          />
          <MainButton
            disabled={!isPasswordValid || password !== rePassword}
            title="تسجيل"
            action={registerResUniversitaire}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start justify-start space-y-8 p-14">
            <img src={logo} alt="" className="mb-2" />
            <h1 className="text-5xl font-bold text-blue-800">مستخدم جديد</h1>
            <div>
              <p>هذا الرابط غير صالح .</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RegisterPage;
