import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table";
import {
  useBlockAgentMutation,
  useBlockUserMutation,
  useDeleteAgentMutation,
  useDeleteUserMutation,
  useGetAgentsMutation,
  useGetAllUsersMutation,
} from "../../store/service/userService";
import { toast } from "react-toastify";
import { GET_USERS } from "../../store/features/usersSlice/usersSlice";
import { useSendMailCreateMutation } from "../../store/service/authService";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Pagination from "../../components/pagination/Pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../components/spinner/Spinner";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";

import { filterList } from "../../utils/filterAgentsBySearchCriteria";
import ManageUserModal from "../../components/modal/User/ManageUserModal";
const animatedComponents = makeAnimated();
const options = [
  { value: "universityName", label: " اسم الجامعة	" },
  { value: "email", label: "البريد الالكتروني" },
  { value: "numero", label: " الهاتف" },
  { value: "speciality", label: " إدارة" },
  { value: "role", label: " الوظيفة " },
  { value: "country", label: "البلد" },
];
const UsersListTable = () => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getAllUsers] = useGetAllUsersMutation();
  const [getAgents] = useGetAgentsMutation();
  const [sendMailCreate] = useSendMailCreateMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [deleteAgent] = useDeleteAgentMutation();
  const [blockUser] = useBlockUserMutation();
  const [blockAgent] = useBlockAgentMutation();

  const agentsList = useSelector((state) => state.users.agents);

  const [agentId, setAgentId] = useState("");
  const [agents, setAgent] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilterInputs, setSelectedFilterInputs] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [status, setStatus] = useState(false);
  const [timer, setTimer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [loading, setLoading] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const initializePagination = () => {
    let currentPerPage = perPage;
    setPerPage(currentPerPage);
    setCurrentPage(1);
  };
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    initializePagination();
  };
  const handleChangeSelect = (e) => {
    setCurrentPage(1);
    setPerPage(e.target.value);
  };

  /**
   * @function resendFunction
   * @description this function resend mail to user
   *
   * @param {*} email
   *
   * @returns toast when success
   */
  const resendFunction = (email) => {
    sendMailCreate({ email }).then((res) => {
      if (res) {
        toastSucces("تمت العملية بنجاح !");
        setTimer(true);
      }
    });
  };

  /**
   * @function _blockUser
   * @description this function block user
   *
   * @param {*} id
   *
   * @returns
   */
  const _blockUser = (id) => {
    if (role === "ROLE_ADMIN_ALECSO") {
      blockUser(id).then(() => {
        getAllUsers().then((res) => {
          setOpen(false);
          setAgent(res.data.data);
          dispatch(GET_USERS({ agents: res.data.data }));
          setOpen(false);
          setTimer(true);
        });
      });
    } else {
      blockAgent(id).then(() => {
        getAgents().then((res) => {
          setOpen(false);
          setAgent(res.data.data);
          dispatch(GET_USERS({ agents: res.data.data }));
          setOpen(false);
          setTimer(true);
        });
      });
    }
  };

  /**
   *
   * @function _deleteUser
   * @description this function delete user
   *
   * @param {*} id
   *
   * @returns
   */
  const _deleteUser = (id) => {
    if (role === "ROLE_ADMIN_ALECSO") {
      deleteUser(id).then((res) => {
        if (res.data) {
          const filtered = agents.filter((agent) => agent.userId !== id);
          dispatch(GET_USERS({ agent: agents }));
          setAgent(filtered);
          setOpen(false);
          setTimer(true);
          toastSucces("تمت العملية بنجاح !");
        }

        if (res.error) {
          toastErrorServer("هل هناك خطب من السرفر !");
          setTimer(true);
        }
      });
    } else {
      deleteAgent(id).then((res) => {
        if (res.data) {
          const filtered = agents.filter((agent) => agent.userId !== id);
          dispatch(GET_USERS({ agents: agents }));
          setAgent(filtered);
          setOpen(false);
          setTimer(true);
          toastSucces("تمت العملية بنجاح !");
        }

        if (res.error) {
          toastErrorServer("هل هناك خطب من السرفر !");
          setTimer(true);
        }
      });
    }
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSelectedFilterInputs(array);
    });

    if (e?.length == []) {
      array.push("");
      setSelectedFilterInputs([]);
    }
    setCurrentPage(1);
  };

  const openModel = (id, _value, _status) => {
    setOpen(!open);
    setAgentId(id);
    setValue(_value);
    if (_status === true) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  useEffect(() => {
    if (role === "ROLE_RESP_UNI") {
      if (!VerifyToken()) {
        logout();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      if (role === "ROLE_ADMIN_ALECSO") {
        getAllUsers().then((res) => {
          dispatch(GET_USERS({ agents: res.data.data }));
          if (searchInput === "" && selectedFilterInputs.length == 0) {
            setLoading(true);
            setAgent(res.data.data);
          }
        });
      } else {
        getAgents().then((res) => {
          dispatch(GET_USERS({ agents: res.data.data }));
          if (searchInput === "" && selectedFilterInputs.length == 0) {
            setLoading(true);
            setAgent(res.data.data);
          }
        });
      }
    } else {
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        toast.error("فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً", {
          position: "top-left",
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      getAllUsers().then((res) => {
        dispatch(GET_USERS({ agents: res.data.data }));
        if (searchInput === "" && selectedFilterInputs.length == 0) {
          setLoading(true);
          setAgent(res.data.data);
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    handleChangeSearch();
    filterList(
      selectedFilterInputs,
      setAgent,
      agentsList,
      searchInput,
      setSearchInput
    );
  }, [searchInput, currentPage, selectedFilterInputs]);

  return (
    <div className="flex flex-col justify-start ">
      <h1 className="p-4"> بيانات المستخدمين</h1>
      <div className="flex items-center justify-between w-full gap-3 px-2">
        <input
          type={"text"}
          className="w-1/4 h-10 transition ease-in-out delay-200 border rounded outline-none focus:border-blue-300 focus:border-2"
          placeholder="ابحث  ...!"
          value={searchInput}
          onChange={handleChange}
        />
        <Select
          options={options}
          className="w-2/3"
          closeMenuOnSelect={false}
          placeholder="اختار ...!"
          components={animatedComponents}
          onChange={handleChangeSearch}
          styles={{ height: "3rem", borderWidth: "2px" }}
          isMulti
        />
        {agents?.length > 5 ? (
          <select
            className="w-1/12 h-10 border rounded focus:outline-blue-600 focus:border-none"
            onChange={handleChangeSelect}
            type="number"
            value={perPage}
          >
            <option type="number" value={5}>
              5
            </option>
            <option type="number" value={10}>
              10
            </option>
            <option type="number" value={20}>
              20
            </option>
            <option type="number" defaultValue={30}>
              30
            </option>

            <option type="number" value={50}>
              50
            </option>
          </select>
        ) : null}
      </div>
      <div className="relative pt-10 overflow-x-auto  sm:rounded-lg h-[auto] ">
        {loading ? (
          <Table
            agents={agents}
            open={open}
            setOpen={setOpen}
            setAgentId={setAgentId}
            setAgent={setAgent}
            _blockUser={_blockUser}
            openModel={openModel}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resendFunction={resendFunction}
            perPage={perPage}
          />
        ) : (
          <div className="h-[300px] flex items-center justify-center ">
            <Spinner />
          </div>
        )}

        {open ? (
          <ManageUserModal
            setOpen={setOpen}
            open={open}
            agentId={agentId}
            _deleteUser={_deleteUser}
            _blockUser={_blockUser}
            status={status}
            timer={timer}
            value={value}
          />
        ) : null}
      </div>
      <div className="flex justify-start">
        {agents?.length > 5 ? (
          <Pagination
            perPage={perPage}
            total={agents.length}
            paginate={paginate}
          />
        ) : null}
      </div>
    </div>
  );
};

UsersListTable.propTypes = {
  agents: PropTypes.array,
  agentId: PropTypes.string,
  setAgentId: PropTypes.func,
  setAgent: PropTypes.func,
  setPerPage: PropTypes.func,
};
export default UsersListTable;
