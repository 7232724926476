import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
const ProtectedRoute = ({ children }) => {
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("role") !== "student"
  ) {
    return children;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  if (localStorage.getItem("role") === "student") {
    return <Navigate to="/login_student" />;
  }
};
ProtectedRoute.propTypes = {
  children: PropTypes.object,
};
export default ProtectedRoute;
