import React from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";

import useModal from "../hooks/useModal";
const ModalLayout = ({ children }) => {
  const { isShowing } = useModal();

  return (
    <>
      <Transition
        show={isShowing}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          {children}
        </div>
      </Transition>
      <div className="fixed inset-0 bg-black z-5 opacity-60"></div>
    </>
  );
};
ModalLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
export default ModalLayout;
