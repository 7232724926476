/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: false,
  role: null,
  email: null,
  university: null,
  numero: null,
  speciality: null,
  username: null,
  isAccepted: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    LOADING_USER: (state, action) => {
      state.accessToken = true;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.numero = action.payload.numero;
      state.email = action.payload.email;
      state.university = action.payload.university;
      state.speciality = action.payload.speciality;
      state.isAccepted = action.payload.isAccepted;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  SIGN_IN,
  ADD_FROM_CSV,
  SELECT_STUDENT,
  ADD_DIPLOMAS_FROM_CSV,
  SELECT_DIPLOMA,
  ADD_PDFS,
  GET_STUDENTS,
  ADD_FROM_CSV_DIPLOMAS,
  ADD_FILES,
  LOADING_USER,
} = userSlice.actions;

export default userSlice.reducer;
