/* eslint-disable react/prop-types */
import React from "react";
import { Select, Option } from "@material-tailwind/react";
import PropTypes from "prop-types";

function SelectGender({ value, setSex }) {
  const handleOnChange = (e) => {
    setSex(e);
  };
  return (
    <form className="w-full ">
      <div className="relative mb-6">
        <Select
          defaultValue={"ذكر"}
          placeholder={"ذكر"}
          variant="static"
          label=""
          value={value}
          onChange={handleOnChange}
          className={
            "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-full pr-10 p-2.5 flex items-start justify-start "
          }
        >
          <Option value="ذكر">ذكر</Option>
          <Option value="أنثى">أنثى</Option>
        </Select>
      </div>
    </form>
  );
}

SelectGender.propTypes = {
  value: PropTypes.string.isRequired,
  setSex: PropTypes.func.isRequired,
};
export default SelectGender;
