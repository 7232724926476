import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStudentbyIdMutation } from "../../store/service/studentsService";
import DiplomaInfo from "./DiplomaInfo";
import StudentInfo from "./StudentInfo";

const StudentFich = () => {
  const params = useParams();
  const [getStudentById] = useGetStudentbyIdMutation();
  const [student, setStudent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStudentById(params.id).then((res) => {
      setStudent(res.data.data);

      setIsLoading(true);
    });
  }, [isLoading]);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-end mt-10 px-[3%]">
      <div className="max-w-[auto] flex flex-col  justify-center  p-4 bg-white h-fit rounded-md">
        <div className="flex  justify-start  h-[auto] ">
          <StudentInfo student={student} />
          {student.diplomas?.length > 0 ? (
            <div className="flex flex-wrap items-start justify-between w-full mt-4 overflow-auto  rounded-xl max-w-[40vw]">
              <div className="thirdParty flex w-full justify-between  pt-4 ">
                {student.diplomas?.map((diploma) => {
                  // eslint-disable-next-line react/jsx-key
                  return <DiplomaInfo diploma={diploma} key={diploma} />;
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex justify-end ">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className=" mt-2 p-1 px-7 text-md text-white bg-blue-900 rounded w-fi h-fit"
          >
            رجوع
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentFich;
