import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import {
  useCreateAgentUniMutation,
  useCreateRespUniMutation,
} from "../../store/service/usersService";
import {
  mailIcon,
  mobileIcon,
  specialityIcon,
  univestyIcon,
  userIcon,
} from "../../assets/svg/Index";
import SelectCountry from "../../components/input/SelectCountry";
import { clearLocalStorage } from "../../utils/logout";
import { toastErrorServer } from "../../components/toast/Toast";

function AddUserPage() {
  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isSpecialityValid, setIsSpecialityValid] = useState(false);
  const [numero, setNumero] = useState("");
  const [isNumeroValid, setIsNumeroValid] = useState(false);
  const [universityName, setUniversityName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [country, setCountry] = useState("");
  const [isUniversityNameValid, setIsUniversityNameValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createRespUniv] = useCreateRespUniMutation();
  const [createAgentUniv] = useCreateAgentUniMutation();
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  /**
   * @function notify
   * @description this function to create notify
   *
   * @param(msg)
   * @param(type)
   *
   * @return
   */
  const notify = (msg, type) => {
    if (type === "info") {
      toast.info(msg, {
        position: "top-left",
      });
    } else {
      toast.error(msg, {
        position: "top-left",
      });
    }
  };

  /**
   * @function addUser
   * @description this function to add User
   *
   * @return
   */
  const addUser = () => {
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      clearLocalStorage();
      toastErrorServer("فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setIsLoading(true);
      if (name === "" || email === "" || numero === "") {
        toastErrorServer("المعطيات ناقصة ، الرجاء إدخال المعطيات كاملة");
      } else {
        switch (role) {
          case "ROLE_ADMIN_ALECSO": {
            let data = {
              username: name,
              email: email,
              adminName: user.username,
              adminMail: user.email,
              universityName: universityName,
              numero: numero,
              country,
            };

            return createRespUniv(data).then((res) => {
              if (res.data) {
                setIsLoading(false);
                setEmail("");
                setName("");
                setNumero("");
                setUniversityName("");
                setCountry("");
                notify("تمت الاضافة بنجاح ", "info");
              }
              if (res.error.status === 400) {
                setIsLoading(false);
                notify(
                  "  هذا المستخدم يوجد ، حاول إستخدام عنوان إلكتروني  و إسم أخر  ",
                  "info"
                );
              } else {
                setIsLoading(false);
                notify("   هناك خطأ في سرفير   ", "error");
              }
            });
          }

          case "ROLE_RESP_UNI": {
            let data = {
              username: name,
              email: email,
              adminName: user.username,
              adminMail: user.email,
              universityName: user.universityName,
              speciality: speciality,
              numero: numero,
              country,
            };
            return createAgentUniv(data).then((res) => {
              if (res.data) {
                setIsLoading(false);
                setEmail("");
                setName("");
                setNumero("");
                setSpeciality("");
                notify("تمت الاضافة بنجاح ", "info");
              }
              if (res.error.status === 400) {
                setIsLoading(false);
                notify(
                  " هذا المستخدم يوجد ، حاول إستخدام عنوان إلكتروني أخر  ",
                  "info"
                );
              } else {
                setIsLoading(false);
                notify("   هناك خطأ في سرفير   ", "error");
              }
            });
          }
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full p-4 space-y-2">
      <h1 className="mt-10 text-2xl font-bold text-blue-900">إضافة مستخدم</h1>
      <p className="text-gray-600">
        لإضافة مستخدم يجب تعمير البيانات التالية لإرسال رابط تسجيل خاص لإكمال
        عملية تسجيل الموظف ضمن مستخدمي المنصّة
      </p>
      <div className="flex flex-col items-start justify-center w-full p-8 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">الاسم الكامل</p>
          <Input
            icon={userIcon}
            type={"NAME"}
            placeholder={"اسم ولقب المستخدم"}
            inputChange={setName}
            value={name}
            setValid={setIsNameValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900"> البريد الالكتروني</p>
          <Input
            icon={mailIcon}
            type={"EMAIL"}
            placeholder={"  البريد الالكتروني لإرسال طلب التسجيل"}
            inputChange={setEmail}
            value={email}
            setValid={setIsEmailValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900"> الهاتف</p>
          <Input
            icon={mobileIcon}
            type={"NUMBER"}
            placeholder={"  هاتف الموظف    "}
            inputChange={setNumero}
            value={numero}
            setValid={setIsNumeroValid}
          />
        </div>
        {role === "ROLE_RESP_UNI" && (
          <>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900"> إدارة </p>
              <Input
                icon={specialityIcon}
                type={"SPECIALITY"}
                placeholder={"    إدارة     "}
                inputChange={setSpeciality}
                value={speciality}
                setValid={setIsSpecialityValid}
              />
            </div>
          </>
        )}
        {role === "ROLE_ADMIN_ALECSO" && (
          <>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900"> الجامعة </p>
              <Input
                icon={univestyIcon}
                type={"University"}
                placeholder={"   إسم الجامعة     "}
                inputChange={setUniversityName}
                value={universityName}
                setValid={setIsUniversityNameValid}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900"> البلد </p>
              <SelectCountry country={country} setCountry={setCountry} />
            </div>
          </>
        )}

        <div className="flex justify-end w-full">
          <div className="w-1/3">
            {role === "ROLE_RESP_UNI" && (
              <MainButton
                isLoading={isLoading}
                action={addUser}
                title="ارسال طلب تسجيل"
                disabled={
                  !isNameValid ||
                  !isEmailValid ||
                  !isNumeroValid ||
                  !isSpecialityValid
                }
              />
            )}

            {role === "ROLE_ADMIN_ALECSO" && (
              <>
                <MainButton
                  isLoading={isLoading}
                  action={addUser}
                  title="ارسال طلب تسجيل"
                  disabled={
                    !isNameValid ||
                    !isEmailValid ||
                    !isNumeroValid ||
                    !isUniversityNameValid ||
                    country === ""
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUserPage;
