import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import PropTypes from "prop-types";
import { CategoryScale } from "chart.js";
Chart.register(CategoryScale);
const BarChart = ({ dataBarChart }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length == 1) {
        event.native.target.style.cursor = "pointer";
      }
    },
    scales: {
      y: {
        color: "white",
        drawBorder: false,
        grid: {
          borderColor: "green",
          borderWidth: 3,
          drawBorder: false,
        },
      },
      x: {
        color: "white",
        drawBorder: false,
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div className="w-full h-auto  bg-white rounded p-3">
      <span className="text-1xl font-bold text-indigo-900">
        الزيارات الشهرية للتثبت من مصداقية الشهادات
      </span>
      <div className="pt-6">
        <Bar data={dataBarChart} options={options} height={90} />
      </div>
    </div>
  );
};
BarChart.propTypes = {
  dataBarChart: PropTypes.object,
};
export default BarChart;
