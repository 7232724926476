/* eslint-disable react/prop-types */
import React, { useState } from "react";
import QrReader from "react-qr-scanner";

function QRScan({ result, setResult, setShowCamera }) {
  // eslint-disable-next-line no-unused-vars
  const [delay] = useState(100);
  const containerStyle = {
    height: 50,
  };
  const handleScan = (data) => {
    setResult(data);
    if (result?.text) {
      setResult(result?.text);
      setShowCamera(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div className="h-[100px]!">
      <QrReader
        delay={delay}
        onError={handleError}
        onScan={handleScan}
        containerStyle={containerStyle}
      />
    </div>
  );
}

export default QRScan;
