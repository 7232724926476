/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  role: null,
  username: null,
  email: null,
  universityName: null,
  numberOfLogins: 0,
  lastLoginDate: null,
  numero: null,
  isAccepted: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SIGN_IN: (state, action) => {
      state.accessToken = action.payload.token;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.numero = action.payload.numero;
      state.email = action.payload.email;
      state.universityName = action.payload.universityName;
      state.numberOfLogins = action.payload.numberOfLogins;
      state.lastLoginDate = action.payload.lastLoginDate;
      state.isAccepted = action.payload.isAccepted;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SIGN_IN } = authSlice.actions;

export default authSlice.reducer;
