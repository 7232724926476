import React, { useEffect } from "react";

function LandingPage() {
  useEffect(() => {
    window.location.replace("https://saddakni.rnu.tn/LandingPage.html");
  }, []);
  return <></>;
}

export default LandingPage;
