import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import PropTypes from "prop-types";
import {
  ADD_FROM_CSV,
  SELECT_STUDENT,
} from "../../store/features/studentsSlice/studentsSlice";
import { useAddStudentsMutation } from "../../store/service/studentsService";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";

function AddStudentsTable({
  setErrorAdd,
  errorTable,
  updateTable,
  setOpenModal,
  setKey,
  setStudentToDelete,
}) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [addStudents] = useAddStudentsMutation();
  const studentsToAdd = useSelector((state) => state.students.studentsToAdd);
  const [isLoading, setIsLoading] = useState(false);
  const studentsData = [...studentsToAdd];

  /**
   * @function openModal
   * @description this function to open Model and  delete Student
   *
   * @param(key)
   * @param(student)
   *
   * @return
   */
  const openModal = (key, student) => {
    setOpenModal((prev) => !prev);
    setKey(key);
    setStudentToDelete(student);
  };

  /**
   * @function uploadJSON
   * @description this function to conver array to json
   *
   * @return data
   */
  const uploadJSON = () => {
    let data = studentsData.map((student) => {
      return {
        firstName: student.firstName ? student.firstName : "",
        lastName: student.lastName ? student.lastName : "",
        nin: student.nin ? student.nin : "",
        dateOfBirth: student.dateOfBirth ? student.dateOfBirth : "",
        countryOfBirth: student.countryOfBirth ? student.countryOfBirth : "",
        sex: student.sex ? student.sex : "",
        nationalty: student.nationalty ? student.nationalty : "",
        phone: student.phone ? student.phone : "",
        email: student.email ? student.email : "",
      };
    });

    return data;
  };

  /**
   * @function addStudentsClick
   * @description this function to Add Students
   *
   * @return
   */
  const addStudentsClick = () => {
    setIsLoading(true);
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      addStudents({ students: uploadJSON() }).then(async (res) => {
        if (res?.error?.status === 400) {
          toastErrorServer(" هناك خطأ من السرفر ");
          setIsLoading(false);
        } else if (
          ((await res?.data?.notAddedStudents) !== undefined &&
            res?.data?.notAddedStudents.length !== 0) ||
          ((await res?.data?.updatedStudents) !== undefined &&
            res?.data?.updatedStudents.length !== 0)
        ) {
          await setErrorAdd(true);

          await errorTable.push([res?.data?.notAddedStudents]);
          await updateTable.push([res?.data?.updatedStudents]);
          setIsLoading(false);
        } else {
          toastSucces("تمت العملية بنجاح !");
          setIsLoading(false);
        }
      });
    }
    dispatch(ADD_FROM_CSV({ studentsToAdd: [] }));
  };

  return (
    <div className="flex flex-col justify-start ">
      <h1 className="p-4 ">بيانات الطلبة</h1>
      <input
        type={"text"}
        className="w-40 h-10 p-4 transition ease-in-out delay-200 border rounded outline-none focus:border-blue-300 focus:border-2"
        placeholder="ابحث  ..."
      />
      <div className="relative  overflow-x-auto  sm:rounded-lg h-[auto] ">
        <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                الاسم الأول
              </th>
              <th scope="col" className="px-6 py-3">
                اسم العائلة
              </th>
              <th scope="col" className="px-6 py-3">
                المعرف الوطني
              </th>
              <th scope="col" className="px-6 py-3">
                تاريخ الولادة
              </th>
              <th scope="col" className="px-6 py-3">
                مكان الولادة
              </th>
              <th scope="col" className="px-6 py-3">
                الجنس
              </th>
              <th scope="col" className="px-6 py-3">
                الجنسية
              </th>

              <th scope="col" className="px-6 py-3">
                الهاتف
              </th>
              <th scope="col" className="px-6 py-3">
                البريد الالكتروني
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody className="overflow-x-hidden h-[auto]">
            {studentsToAdd?.map((student, key) => {
              return (
                <tr
                  key={key}
                  className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                    (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
                  }`}
                >
                  <th className="px-6 py-2"> {student.firstName}</th>
                  <td className="px-6 py-2"> {student.lastName}</td>
                  <td className="px-6 py-2">{student.nin}</td>
                  <td className="px-6 py-2"> {student.dateOfBirth}</td>
                  <td className="px-6 py-2"> {student.countryOfBirth}</td>
                  <td className="px-6 py-2"> {student.sex}</td>
                  <td className="px-6 py-2"> {student.nationalty}</td>
                  <td className="px-6 py-2"> {student.phone}</td>
                  <td className="px-6 py-2"> {student.email}</td>

                  <td className="flex items-center justify-center px-6 py-2 ">
                    <button
                      onClick={() => {
                        dispatch(
                          SELECT_STUDENT({
                            studentSelected: { ...student, key },
                          })
                        );
                        navigate("/update_one_student");
                      }}
                      className="p-1 mx-1 text-xs font-bold text-white bg-blue-400 rounded w-fi h-fit"
                    >
                      تعديل
                    </button>

                    <button
                      onClick={() => {
                        openModal(key, student);
                      }}
                      className="p-1 mx-1 text-xs font-bold text-white bg-red-400 rounded w-fi h-fit"
                    >
                      حذف
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="w-full p-4 ">
        <div className="flex justify-start w-32">
          <MainButton
            title=" تأكيد الإضافة"
            action={addStudentsClick}
            isLoading={isLoading}
            disabled={studentsToAdd?.length == 0}
          />
        </div>
      </div>
    </div>
  );
}
AddStudentsTable.propTypes = {
  setErrorAdd: PropTypes.func,
  errorTable: PropTypes.array,
  updateTable: PropTypes.array,
  setOpenModal: PropTypes.func,
  setKey: PropTypes.func,
  setStudentToDelete: PropTypes.func,
};
export default AddStudentsTable;
