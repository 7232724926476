import React, { useState, useEffect } from "react";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import logo from "../../assets/certified_logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../store/service/usersService";
import { passwordIcon } from "../../assets/svg/Index";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";

function ForgotPasswordPage() {
  let { token } = useParams();
  let navigate = useNavigate();
  let [updatePassword] = useResetPasswordMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [rePassword, setRePassword] = useState("");

  const ForgotPassword = () => {
    const data = {
      password,
      token,
    };
    updatePassword(data).then((res) => {
      if (res.data) {
        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
          toastSucces("تمت العملية بنجاح ");
          navigate("/login");
        }, 3000);
      } else {
        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
          toastErrorServer("انتهت صلاحية الرابط");
        }, 1000);
      }
    });
  };
  useEffect(() => {
    if (isLoading) {
      setPassword("");
      setRePassword("");
    }
  }, [isLoading]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="flex flex-col items-start justify-start space-y-8 p-14">
      <img src={logo} alt="" className="mb-2" />
      <h1 className="text-5xl font-bold text-blue-800"> تغير كلمة السر </h1>

      <Input
        icon={passwordIcon}
        placeholder="  كلمة السر"
        password
        value={password}
        inputChange={setPassword}
        setValid={setIsPasswordValid}
        type="PASSWORD"
      />
      <Input
        icon={passwordIcon}
        placeholder=" إعادة كلمة السر "
        password
        value={rePassword}
        inputChange={setRePassword}
        setValid={setIsPasswordValid}
        type="PASSWORD_LOGIN"
      />
      <MainButton
        title="تأكيد  "
        action={ForgotPassword}
        disabled={
          !isPasswordValid ||
          (password === "" && rePassword === "") ||
          password !== rePassword
        }
        isLoading={isLoading}
      />
      <div className="flex items-center justify-center w-full mt-20 text-2xl font-bold">
        <p> الرجوع إلى صفحة </p>
        <p
          className="mr-2 text-blue-800 cursor-pointer"
          onClick={() => {
            navigate("/login");
          }}
        >
          تسجيل الدخول .
        </p>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
