import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <div className="flex items-center  justify-center h-[95vh] bg-[#f2f7ff]">
        <div className="grid gap-3">
          <span className="text-indigo-900 text-center font-extrabold text-9xl">
            404
          </span>
          <span className=" text-center text-blue-gray-900 font-bold text-5xl">
            الصفحة غير موجودة
          </span>
        </div>
      </div>
      <div className="flex items-end justify-end p-1 bg-[#f2f7ff]">
        <button
          onClick={back}
          className="p-1  text-white bg-indigo-600 rounded text-md w-fi h-fit"
        >
          رجوع
        </button>
      </div>
    </>
  );
};

export default NotFound;
