import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Spinner from "../spinner/Spinner";

function MainButton({ title, action, isLoading, disabled }) {
  const onClick = () => {
    action();
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onClick();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  const baseButtonStyle =
    "w-full h-10 text-white bg-[#435ebe] shadow-lg rounded-md hover:bg-[#3950a2]";
  const disabledButtonStyle = "cursor-not-allowed";
  const buttonStyle = disabled
    ? `${baseButtonStyle} ${disabledButtonStyle}`
    : baseButtonStyle;

  return (
    <button
      type="submit"
      disabled={disabled || isLoading}
      onClick={onClick}
      className={buttonStyle}
    >
      {isLoading ? (
        <div data-testid="spinner" className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        title
      )}
    </button>
  );
}
MainButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default MainButton;
