function readDateFromLeftToRight(inputDate) {
  // Split the input date into day, month, and year components
  const parts = inputDate.split("-");
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];

  // Combine the components into a new string in "yyyy-mm-dd" format
  const formattedDate = year + "-" + month + "-" + day;

  // Return the formatted date string
  return formattedDate;
}
export default readDateFromLeftToRight;
