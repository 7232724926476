import React from "react";

import StudentsTable from "./StudentsTable";

const StudentsList = () => {
  return (
    <div>
      <div className="flex flex-col justify-end mt-10 px-[3%]">
        <h1 className="mb-2 text-2xl font-bold text-[#25396f]">قائمة الطلبة</h1>
        <h5 className="mb-2 text-[#7c8db5] text-1xl">
          للاطلاع والبحث والتعديل على قائمة الطلبة
        </h5>
        <div className="flex flex-col w-full p-2 bg-white rounded-md h-fit">
          <div className="w-full">
            <StudentsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsList;
