import React from "react";
import PropTypes from "prop-types";
import DeleteUserModal from "./DeleteUserModal";
import BlockUserModal from "./BlockUserModal";
const ManageUserModal = ({
  setOpen,
  open,
  agentId,
  _deleteUser,
  value,
  _blockUser,
  status,
}) => {
  return (
    <>
      {value === "delete" ? (
        <DeleteUserModal
          setOpen={setOpen}
          open={open}
          agentId={agentId}
          _deleteUser={_deleteUser}
        />
      ) : (
        <BlockUserModal
          setOpen={setOpen}
          open={open}
          agentId={agentId}
          _blockUser={_blockUser}
          status={status}
        />
      )}
    </>
  );
};
ManageUserModal.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  agentId: PropTypes.string,
  _deleteUser: PropTypes.func,
  value: PropTypes.string,
  _blockUser: PropTypes.func,
  status: PropTypes.bool,
};
export default ManageUserModal;
